import { apiReq } from '../index'
import QueryString from 'qs'
import { getSearchCriteriaFromStore } from '../helpers'
import { PageListParams } from '../globalTypes'
import { Property } from './types'

export const getPropertyById = async (id: number) => {
    const response = await apiReq(`v1/property/${id}`, {
        params: getSearchCriteriaFromStore('property'),
        paramsSerializer: (params: unknown) => {
            return QueryString.stringify(params)
        },
    })

    return response?.data
}

export const getProperties = async (payload: PageListParams) => {
    const response = await apiReq(`v1/property`, {
        params: payload,
        paramsSerializer: (params: PageListParams) => {
            return QueryString.stringify(params)
        },
    })

    return response?.data
}

export const getOpportunityById = async (id: number, page: number, limit: number, order: { [key: string]: string | number }, url: string) => {
    const key = Object.keys(order)[0]
    const paramsLink = `criteria[property][0]=${id}&limit=${limit}&order[${key}]=${order[`${key}`]}&page=${page}`
    const paramsEncoded = QueryString.stringify(QueryString.parse(paramsLink))
    const apiUrl = `v1/${url}?${paramsEncoded}`

    const response = await apiReq(apiUrl)

    return response?.data
}

export const createUpdateProperty = async (data: Property, isEdit?: boolean) => {
    const url = isEdit ? `v1/property/${data.id}` : `v1/property`

    const response = await apiReq(url, {
        method: isEdit ? 'PUT' : 'POST',
        data: data,
    })

    if (response.status !== 200 && response.status !== 201) {
        throw new Error()
    }

    return response?.data
}

export const deletePropertyById = async (id: number) => {
    const response = await apiReq(`v1/property/${id}`, {
        method: 'DELETE',
    })

    if (response.status !== 200 && response.status !== 201) {
        throw new Error()
    }

    return response?.data
}

export const deleteMultipleProperties = async (arrayId: number[]) => {
    const response = await Promise.all(
        arrayId.map(
            async (id) =>
                await apiReq(`v1/property/${id}`, {
                    method: 'DELETE',
                })
        )
    )
    return response
}
