import { PROPERTY_STATUS } from '../../../common/constants'
import { checkPermissionLevel } from './utils'

// this is related to select options in property Transaction tab, webSync checkbox is handled via editData
// also is used in PropertyCreate to give default status to newly created property
export const setPropertyStatus = ({ agentPermissions, selectOptions = [], propertyStatus }) => {
    const permission = 'module.property.authorization'
    const level = checkPermissionLevel(agentPermissions, permission)
    const isWebSyncAllowed = level === 'yes'
    const { ACTIVE_OFFER, FOR_AUTHORIZATION } = PROPERTY_STATUS

    return {
        newSelectOptions: isWebSyncAllowed ? selectOptions : filterTransactionSelectOptions(selectOptions, propertyStatus?.id),
        defaultStatus: isWebSyncAllowed ? ACTIVE_OFFER : FOR_AUTHORIZATION,
    }
}

const filterTransactionSelectOptions = (options, statusId) => {
    const propertyStatusKey = 'property_status_id'
    return {
        ...options,
        [propertyStatusKey]: options?.[propertyStatusKey]?.filter((obj) => obj.value === statusId?.toString()),
    }
}
