import i18next from 'i18next'
import { Link } from 'react-router-dom'
import { findEntityTitle, formatDateTime, formatNumberWithTwoDecimals } from '../../utils/general'
import { renderAreaUnit, renderCurrency, renderCurrencyWithUnit } from '../../utils/property'
import { IS_ERSTE, REAL_ESTATE_TYPES } from '../constants'
import responseFields from '../constants/responseFields'
import { projectResponseFields } from '../response/project'
import { propertyResponseFields } from '../response/property'
import { getAgentsViewData, getOwnersViewData } from './utils'

const getSharedViewData = (data, config, isSale) => {
    const { t } = i18next
    const currency = renderCurrency(config.currency)
    const currencyWithUnit = renderCurrencyWithUnit(config?.currency, config?.areaUnit)
    const unit = renderAreaUnit(config?.areaUnit)
    const propertyTransaction = data?.propertyTransaction
    const IS_SLO_MARKET = config?.applicationMarket.name === 'Slovenija'

    const vatField = isSale ? 'VAT_INCLUDED' : 'VAT_NOT_INCLUDED'

    return {
        general: [
            /* {
                id: propertyResponseFields.ID,
                label: 'ID',
                value: data?.[propertyResponseFields.ID]
            },
            {
                id: propertyResponseFields.INTERNAL_ID,
                label: 'ID agencije',
                value: data?.[propertyResponseFields.INTERNAL_ID]
            }, */
            {
                id: `${propertyResponseFields.PROPERTY_SUB_TYPE}_name`,
                label: t('app.common.property.subtype'),
                value: data?.[propertyResponseFields.PROPERTY_SUB_TYPE]?.name,
            },
            IS_ERSTE && {
                id: `${propertyResponseFields.PROPERTY_SET}_name`,
                label: t('view.property.general.set'),
                value: data?.[propertyResponseFields.PROPERTY_SET]?.name,
            },
            {
                id: `${propertyResponseFields.PROPERTY_TRANSACTION}_reservationDate`,
                label: t('view.property.general.reservationDate'),
                value: formatDateTime(data?.[propertyResponseFields.PROPERTY_TRANSACTION]?.reservationDate),
            },
            {
                id: propertyResponseFields.PROPERTY_LAND_USAGE,
                label: t('app.common.property.landUsage'),
                value: data?.[propertyResponseFields.PROPERTY_LAND_USAGE]?.name,
            },
            {
                id: propertyResponseFields.PROPERTY_POSITION,
                label: t('app.common.property.position'),
                value: data?.[propertyResponseFields.PROPERTY_POSITION]?.name,
            },
            {
                id: propertyResponseFields.PROPERTY_PRIMARY_USAGE,
                label: t('app.common.property.primaryUsage'),
                value: data?.[propertyResponseFields.PROPERTY_PRIMARY_USAGE]?.name,
            },
            {
                id: propertyResponseFields.PROPERTY_RECEIPT_DATE,
                label: t('app.common.project.receiptDate'),
                value: formatDateTime(data?.[propertyResponseFields.PROPERTY_RECEIPT_DATE]),
            },
            {
                id: propertyResponseFields.CONTRACT_NO,
                label: t('app.common.contractNumber'),
                value: data?.[propertyResponseFields.CONTRACT_NO],
            },
            {
                id: propertyResponseFields.CONTRACT_DATE,
                label: t('app.common.contractDate'),
                value: formatDateTime(data?.contractDate),
            },
            {
                id: propertyResponseFields.CREATED,
                label: t('view.common.other.created'),
                value: formatDateTime(data?.[propertyResponseFields.CREATED]),
            },
            {
                id: propertyResponseFields.UPDATED,
                label: t('app.common.updated'),
                value: formatDateTime(data?.[propertyResponseFields.UPDATED]),
            },
            {
                id: responseFields.PROPERTY_SUPPLY_SOURCE,
                label: t('app.common.offerSource'),
                value: data?.[responseFields.PROPERTY_SUPPLY_SOURCE]?.name,
            },
            {
                id: projectResponseFields.PROJECT_TITLE,
                label: t('app.common.project.title'),
                value: data?.[propertyResponseFields.PROJECT]?.id ? (
                    <Link to={`/projects/${data?.[propertyResponseFields.PROJECT].id}`} target="_blank" className="dmn-link">
                        {findEntityTitle(data?.[propertyResponseFields.PROJECT], projectResponseFields.PROJECT_TITLE)}
                    </Link>
                ) : null,
            },
        ],
        location: [
            {
                id: propertyResponseFields.COUNTRY,
                label: t('app.common.country'),
                value: data?.[propertyResponseFields.COUNTRY]?.name,
            },
            {
                id: propertyResponseFields.COUNTY,
                label: t('app.common.county'),
                value: data?.[propertyResponseFields.COUNTY]?.name,
            },
            {
                id: propertyResponseFields.CITY,
                label: t('app.common.cityMunicipality'),
                value: data?.[propertyResponseFields.CITY]?.name,
            },
            {
                id: propertyResponseFields.WIDER_AREA,
                label: t('app.common.widerArea'),
                value: data?.[propertyResponseFields.WIDER_AREA]?.name,
            },
            {
                id: propertyResponseFields.QUARTER,
                label: t('app.common.quarter'),
                value: data?.[propertyResponseFields.QUARTER]?.name,
            },
            {
                id: propertyResponseFields.ISLAND,
                label: t('app.common.island'),
                value: data?.[propertyResponseFields.ISLAND]?.name,
            },
            {
                id: `${propertyResponseFields.STREET}-${propertyResponseFields.STREET_NUMBER}`,
                label: t('view.common.location.street'),
                value: data?.[propertyResponseFields.STREET]
                    ? `${data?.[propertyResponseFields.STREET] ?? ''} ${data?.[propertyResponseFields.STREET_NUMBER] ?? ''}`
                    : null,
                icon: data?.[propertyResponseFields.STREET_SYNC] ? <i className={`ms-1 text-success mdi mdi-check-circle fs-16`}></i> : null,
            },
        ],
        owners: getOwnersViewData(data?.[propertyResponseFields.OWNER]),
        zk: [
            {
                id: `${propertyResponseFields.PROPERTY_MAIN_REGISTER}_${propertyResponseFields.PROPERTY_MAIN_REGISTER_DEPARTMENT}`,
                label: t('view.property.registry.main'),
                value:
                    propertyTransaction?.[propertyResponseFields.PROPERTY_MAIN_REGISTER] ||
                    propertyTransaction?.[propertyResponseFields.PROPERTY_MAIN_REGISTER_DEPARTMENT] ? (
                        <>
                            <div>{propertyTransaction?.[propertyResponseFields.PROPERTY_MAIN_REGISTER]?.name}</div>
                            <div>{propertyTransaction?.[propertyResponseFields.PROPERTY_MAIN_REGISTER_DEPARTMENT]?.name}</div>
                        </>
                    ) : (
                        ''
                    ),
            },
            {
                id: propertyResponseFields.PROPERTY_REGISTRY_MUNICIPALITY,
                label: t('app.common.registry.municipality'),
                value: propertyTransaction?.[propertyResponseFields.PROPERTY_REGISTRY_MUNICIPALITY],
            },
            {
                id: propertyResponseFields.PROPERTY_REGISTRY_INSERT,
                label: t('app.common.registry.insert'),
                value: propertyTransaction?.[propertyResponseFields.PROPERTY_REGISTRY_INSERT],
            },
            {
                id: propertyResponseFields.PROPERTY_REGISTRY_PARCEL_NO,
                label: t('app.common.registry.parcel'),
                value: propertyTransaction?.[propertyResponseFields.PROPERTY_REGISTRY_PARCEL_NO],
            },
            {
                id: propertyResponseFields.PROPERTY_REGISTRY_SUBINSERT,
                label: t('app.common.registry.subInsert'),
                value: propertyTransaction?.[propertyResponseFields.PROPERTY_REGISTRY_SUBINSERT],
            },
            {
                id: `${propertyResponseFields.PROPERTY_REGISTRY_DEPARTMENT_KPU}_${propertyResponseFields.PROPERTY_REGISTRY_DEPARTMENT_KPU_SUBINSERT}`,
                label: t('view.property.registry.departmentKPU'),
                value:
                    propertyTransaction?.[propertyResponseFields.PROPERTY_REGISTRY_DEPARTMENT_KPU] ||
                    propertyTransaction?.[propertyResponseFields.PROPERTY_REGISTRY_DEPARTMENT_KPU_SUBINSERT] ? (
                        <>
                            {propertyTransaction?.[propertyResponseFields.PROPERTY_REGISTRY_DEPARTMENT_KPU]?.name && (
                                <div>{propertyTransaction?.[propertyResponseFields.PROPERTY_REGISTRY_DEPARTMENT_KPU]?.name} </div>
                            )}
                            {propertyTransaction?.[propertyResponseFields.PROPERTY_REGISTRY_DEPARTMENT_KPU]?.name && (
                                <div>{propertyTransaction?.[propertyResponseFields.PROPERTY_REGISTRY_DEPARTMENT_KPU]?.name}</div>
                            )}
                        </>
                    ) : (
                        ''
                    ),
            },
            {
                id: propertyResponseFields.PROPERTY_SUBINSERT_KPU,
                label: t('view.property.registry.subinsertKPU'),
                value: propertyTransaction?.[propertyResponseFields.PROPERTY_SUBINSERT_KPU],
            },
            {
                id: propertyResponseFields.PROPERTY_PUK_DEPARTMENT,
                label: t('view.property.registry.departmentPUK'),
                value: propertyTransaction?.[propertyResponseFields.PROPERTY_PUK_DEPARTMENT]?.name,
            },
            {
                id: propertyResponseFields.PROPERTY_CADASTRAL_MUNCIPALITY,
                label: t('app.common.cadastralMunicipality'),
                value: propertyTransaction?.[propertyResponseFields.PROPERTY_CADASTRAL_MUNCIPALITY],
            },
            {
                id: propertyResponseFields.PROPERTY_CADASTRAL_PARCEL_NO,
                label: t('app.common.property.cadastralParcel'),
                value: propertyTransaction?.[propertyResponseFields.PROPERTY_CADASTRAL_PARCEL_NO],
            },
            {
                id: propertyResponseFields.PROPERTY_POSSESSORY_SHEET_NO,
                label: t('app.common.property.possessorySheet'),
                value: propertyTransaction?.[propertyResponseFields.PROPERTY_POSSESSORY_SHEET_NO],
            },
            IS_SLO_MARKET && {
                id: propertyResponseFields.PROPERTY_BUILDING_NUMBER,
                label: t('app.property.transaction.buildingNumber'),
                value: propertyTransaction?.[propertyResponseFields.PROPERTY_BUILDING_NUMBER],
            },
            IS_SLO_MARKET && {
                id: propertyResponseFields.PROPERTY_BUILDING_PART_NUMBER,
                label: t('app.property.transaction.buildingPartNumber'),
                value: propertyTransaction?.[propertyResponseFields.PROPERTY_BUILDING_PART_NUMBER],
            },
        ],
        price: [
            {
                id: propertyResponseFields.PRICE,
                label: t('app.common.price', { currency }),
                value: data?.[propertyResponseFields.PRICE] ? formatNumberWithTwoDecimals(data?.[propertyResponseFields.PRICE]) : '',
            },
            {
                id: propertyResponseFields.PRICE_M2,
                label: t('app.common.pricePerUnit', { currencyWithUnit }),
                value: data?.[propertyResponseFields.PRICE_M2] ? formatNumberWithTwoDecimals(data?.[propertyResponseFields.PRICE_M2]) : '',
            },
            {
                id: propertyResponseFields.PRICE_HISTORY,
                label: t('app.common.property.oldPrice', { currency }),
                value:
                    data?.[propertyResponseFields.PRICE_HISTORY]?.length > 0
                        ? data[propertyResponseFields.PRICE_HISTORY].map(
                              ({ price, created }) => `${formatNumberWithTwoDecimals(Number(price))} (${formatDateTime(created)})\n`
                          )
                        : null,
            },
            {
                id: propertyResponseFields.PRICE_INITIAL,
                label: t('app.common.property.initialPrice', { currency }),
                value: data?.[propertyResponseFields.PRICE_INITIAL] ? formatNumberWithTwoDecimals(data?.[propertyResponseFields.PRICE_INITIAL]) : '',
            },
            {
                id: propertyResponseFields.PRICE_CLIENT,
                label: t('app.common.property.priceClient', { currency }),
                value: data?.[propertyResponseFields.PRICE_CLIENT] ? formatNumberWithTwoDecimals(data?.[propertyResponseFields.PRICE_CLIENT]) : '',
            },
            {
                id: propertyResponseFields[vatField],
                label: isSale ? t('app.common.property.vatIncluded') : t('app.common.property.vatNotIncluded'),
                value: data?.[propertyResponseFields[vatField]] ? t('view.common.conclusion.yes') : t('view.common.conclusion.no'),
            },
            {
                id: propertyResponseFields.PRICE_ON_REQUEST,
                label: t('app.common.property.priceOnRequest'),
                value: data?.[propertyResponseFields.PRICE_ON_REQUEST] ? t('view.common.conclusion.yes') : t('view.common.conclusion.no'),
            },
            {
                id: propertyResponseFields.EXCHANGE_POSSIBLE,
                label: t('view.property.price.exchangePossible'),
                value: data?.[propertyResponseFields.EXCHANGE_POSSIBLE] ? t('view.common.conclusion.yes') : t('view.common.conclusion.no'),
            },
            {
                id: propertyResponseFields.MAINTENCE_COSTS,
                label:
                    data?.[propertyResponseFields.PROPERTY_TYPE]?.id?.toString() === REAL_ESTATE_TYPES.officeSpace.toString()
                        ? `${t('view.property.price.maintenanceCost')} ${
                              data?.[propertyResponseFields.MAINTENCE_COSTS_TYPE] ? `(${data?.[propertyResponseFields.MAINTENCE_COSTS_TYPE]})` : ''
                          }`
                        : `${t('view.property.price.provision')} ${
                              data?.[propertyResponseFields.MAINTENCE_COSTS_TYPE] ? `(${data?.[propertyResponseFields.MAINTENCE_COSTS_TYPE]})` : ''
                          }`,
                value: data?.[propertyResponseFields.MAINTENCE_COSTS]
                    ? formatNumberWithTwoDecimals(data[propertyResponseFields.MAINTENCE_COSTS])
                    : null,
            },
            {
                id: propertyResponseFields.COMMON_MAINTENCE_COSTS,
                label: `${t('view.property.price.maintenanceCost')} ${
                    data?.[propertyResponseFields.COMMON_MAINTENCE_COSTS_TYPE]
                        ? `(${data?.[propertyResponseFields.COMMON_MAINTENCE_COSTS_TYPE]})`
                        : ''
                }`,
                value: data?.[propertyResponseFields.COMMON_MAINTENCE_COSTS]
                    ? formatNumberWithTwoDecimals(data[propertyResponseFields.COMMON_MAINTENCE_COSTS])
                    : null,
            },
            {
                id: propertyResponseFields.COMMON_UTILITY_COSTS,
                label: `${t('view.property.price.utilityCost')} ${
                    data?.[propertyResponseFields.COMMON_UTILITY_COSTS_TYPE] ? `(${data?.[propertyResponseFields.COMMON_UTILITY_COSTS_TYPE]})` : ''
                }`,
                value: data?.commonUtilityCosts ? formatNumberWithTwoDecimals(data[propertyResponseFields.COMMON_UTILITY_COSTS]) : null,
            },
            {
                id: propertyResponseFields.MORTGAGE_INFO,
                label: t('app.common.property.mortgage'),
                value: data?.mortgageInfo ? formatNumberWithTwoDecimals(data?.mortgageInfo) : '',
            },
        ],
        details: [
            {
                id: propertyResponseFields.PROPERTY_HOUSE_TYPE,
                label: t('app.common.property.houseType'),
                value: data?.[propertyResponseFields.PROPERTY_HOUSE_TYPE]?.name,
            },
            {
                id: propertyResponseFields.AREA,
                label: t('view.property.details.area', { unit }),
                value: formatNumberWithTwoDecimals(data?.[propertyResponseFields.AREA]),
            },
            {
                id: propertyResponseFields.AREA_NETT,
                label: t('view.property.details.areaNett', { unit }),
                value: data?.[propertyResponseFields.AREA_NETT] ? formatNumberWithTwoDecimals(data?.[propertyResponseFields.AREA_NETT]) : '',
            },
            {
                id: propertyResponseFields.AREA_GARDEN,
                label: t('view.property.details.areaGarden', { unit }),
                value: data?.[propertyResponseFields.AREA_GARDEN] ? formatNumberWithTwoDecimals(data?.[propertyResponseFields.AREA_GARDEN]) : '',
            },
            {
                id: propertyResponseFields.AREA_STORAGE,
                label: t('view.property.details.areaStorage', { unit }),
                value: data?.[propertyResponseFields.AREA_STORAGE] ? formatNumberWithTwoDecimals(data?.[propertyResponseFields.AREA_STORAGE]) : '',
            },
            {
                id: propertyResponseFields.PLOT_AREA,
                label: t('view.property.details.areaPlot', { unit }),
                value: data?.[propertyResponseFields.PLOT_AREA] ? formatNumberWithTwoDecimals(data?.[propertyResponseFields.PLOT_AREA]) : '',
            },
            {
                id: propertyResponseFields.PLOT_WIDTH,
                label: t('app.common.property.plotWidth'),
                value: data?.[propertyResponseFields.PLOT_WIDTH] ? formatNumberWithTwoDecimals(data?.[propertyResponseFields.PLOT_WIDTH]) : '',
            },
            {
                id: propertyResponseFields.PLOT_LENGTH,
                label: t('app.common.property.plotLength'),
                value: data?.[propertyResponseFields.PLOT_LENGTH] ? formatNumberWithTwoDecimals(data?.[propertyResponseFields.PLOT_LENGTH]) : '',
            },
            {
                id: propertyResponseFields.CONSTRUCTION_COEFFICIENT,
                label: t('app.common.property.constructionCoefficient'),
                value: data?.[propertyResponseFields.CONSTRUCTION_COEFFICIENT],
            },
            {
                id: propertyResponseFields.CONSTRUCTION_FLOORS_NO,
                label: t('view.property.details.constructionFloorsNo'),
                value: data?.[propertyResponseFields.CONSTRUCTION_FLOORS_NO],
            },
            {
                id: propertyResponseFields.MAX_CONTRUCTION_AREA,
                label: t('view.property.details.maxConstructionArea', { unit }),
                value: data?.[propertyResponseFields.MAX_CONTRUCTION_AREA]
                    ? formatNumberWithTwoDecimals(data?.[propertyResponseFields.MAX_CONTRUCTION_AREA])
                    : '',
            },
            {
                id: propertyResponseFields.PROPERTY_ROOMS_NO,
                label: t('app.common.property.numberOfRooms'),
                value: data?.[propertyResponseFields.PROPERTY_ROOMS_NO]?.name,
            },
            IS_ERSTE && {
                id: propertyResponseFields.PROPERTY_ROOMS_TYPE,
                label: t('app.common.property.roomsType'),
                value: data?.[propertyResponseFields.PROPERTY_ROOMS_TYPE]?.name ?? '',
            },
            {
                id: propertyResponseFields.HOUSING_UNITS_NUMBER,
                label: t('app.common.property.housingUnitsNumber'),
                value: data?.[propertyResponseFields.HOUSING_UNITS_NUMBER],
            },
            {
                id: propertyResponseFields.ROOMS_NO,
                label:
                    data?.[propertyResponseFields.PROPERTY_TYPE]?.id?.toString() === REAL_ESTATE_TYPES.officeSpace.toString()
                        ? t('view.property.details.roomsNoOffice')
                        : t('app.common.property.numberOfRooms'),
                value: data?.[propertyResponseFields.ROOMS_NO],
            },
            {
                id: propertyResponseFields.BED_NO,
                label: t('app.common.property.bedsNumber'),
                value: data?.[propertyResponseFields.BED_NO],
            },
            {
                id: propertyResponseFields.BEDROOMS_NO,
                label: t('app.common.bedrooms'),
                value: data?.[propertyResponseFields.BEDROOMS_NO],
            },
            {
                id: propertyResponseFields.BATHROOM_TOILET_NO,
                label: t('app.common.property.bathroomToiletNumber'),
                value: data?.[propertyResponseFields.BATHROOM_TOILET_NO],
            },
            {
                id: propertyResponseFields.BATHROOM_NO,
                label: t('app.common.property.bathroomNoToiletNumber'),
                value: data?.[propertyResponseFields.BATHROOM_NO],
            },
            {
                id: propertyResponseFields.COMMON_BATHROOM_NO,
                label: t('app.common.property.bathroomNumber'),
                value: data?.[propertyResponseFields.COMMON_BATHROOM_NO],
            },
            {
                id: propertyResponseFields.TOILET_NO,
                label: t('app.common.property.toiletNumber'),
                value: data?.[propertyResponseFields.TOILET_NO],
            },
            {
                id: propertyResponseFields.PARKING_SPACE_NUMBER,
                label: t('app.common.property.parkingSpaceNumber'),
                value: data?.[propertyResponseFields.PARKING_SPACE_NUMBER],
            },
            {
                id: propertyResponseFields.CEILING_HEIGHT,
                label: t('app.common.property.ceilingHeight'),
                value: data?.[propertyResponseFields.CEILING_HEIGHT]
                    ? formatNumberWithTwoDecimals(data?.[propertyResponseFields.CEILING_HEIGHT])
                    : '',
            },
            {
                id: propertyResponseFields.CONSTRUCTION_YEAR,
                label: t('app.common.property.constructionYear'),
                value: data?.[propertyResponseFields.CONSTRUCTION_YEAR],
            },
            {
                id: propertyResponseFields.ADAPTATION_YEAR,
                label: t('app.common.property.adaptationYear'),
                value: data?.[propertyResponseFields.ADAPTATION_YEAR],
            },
            {
                id: propertyResponseFields.PROPERTY_FLOOR,
                label: t('app.common.numberOfFloors'),
                value: data?.[propertyResponseFields.PROPERTY_FLOOR]?.name,
            },
            {
                id: propertyResponseFields.PROPERTY_FLOOR_POSITION,
                label: t('app.common.floorPosition'),
                value:
                    data?.[propertyResponseFields.PROPERTY_FLOOR_POSITION] && data?.[propertyResponseFields.PROPERTY_FLOOR_POSITION].length ? (
                        <>
                            {data?.[propertyResponseFields.PROPERTY_FLOOR_POSITION].map((item, index) => (
                                <span key={item.id}>
                                    {item.name}
                                    {data?.[propertyResponseFields.PROPERTY_FLOOR_POSITION]?.length - 1 !== index ? ', ' : ''}
                                </span>
                            ))}
                        </>
                    ) : null,
            },
            {
                id: propertyResponseFields.FLOOR_NO,
                label: t('app.common.floor'),
                value: data?.[propertyResponseFields.FLOOR_NO],
            },
            {
                id: propertyResponseFields.FLOORS_TOTAL_NO,
                label: t('app.common.property.floorsTotal'),
                value: data?.[propertyResponseFields.FLOORS_TOTAL_NO],
            },
            {
                id: propertyResponseFields.PROPERTY_ORIENTATION,
                label: t('app.common.property.orientation'),
                value: data?.[propertyResponseFields.PROPERTY_ORIENTATION]?.length
                    ? data?.[propertyResponseFields.PROPERTY_ORIENTATION]?.map((item, index) => (
                          <span key={item.id}>
                              {item.name}
                              {data?.[propertyResponseFields.PROPERTY_ORIENTATION]?.length - 1 !== index ? ', ' : ''}
                          </span>
                      ))
                    : '',
            },
            {
                id: propertyResponseFields.PROPERTY_HEATING,
                label: t('app.common.heating'),
                value: data?.[propertyResponseFields.PROPERTY_HEATING]?.length
                    ? data?.[propertyResponseFields.PROPERTY_HEATING]?.map((item, index) => (
                          <span key={item.id}>
                              {item.name}
                              {data?.[propertyResponseFields.PROPERTY_HEATING]?.length - 1 !== index ? ', ' : ''}
                          </span>
                      ))
                    : '',
            },
            {
                id: propertyResponseFields.PROPERTY_CONDITION,
                label: t('app.common.property.condition'),
                value: data?.[propertyResponseFields.PROPERTY_CONDITION]?.length
                    ? data?.[propertyResponseFields.PROPERTY_CONDITION]?.map((item, index) => (
                          <span key={item.id}>
                              {item.name}
                              {data?.[propertyResponseFields.PROPERTY_CONDITION]?.length - 1 !== index ? ', ' : ''}
                          </span>
                      ))
                    : '',
            },
            {
                id: propertyResponseFields.PROPERTY_WINDOWS_DOORS,
                label: t('app.common.property.carpentry'),
                value: data?.[propertyResponseFields.PROPERTY_WINDOWS_DOORS]?.length
                    ? data?.[propertyResponseFields.PROPERTY_WINDOWS_DOORS]?.map((item, index) => (
                          <span key={item.id}>
                              {item.name}
                              {data?.[propertyResponseFields.PROPERTY_WINDOWS_DOORS]?.length - 1 !== index ? ', ' : ''}
                          </span>
                      ))
                    : '',
            },
            {
                id: propertyResponseFields.PROPERTY_PUBLIC_TRANSPORTATION,
                label: t('app.common.property.publicTransportation'),
                value: data?.[propertyResponseFields.PROPERTY_PUBLIC_TRANSPORTATION]?.length
                    ? data?.[propertyResponseFields.PROPERTY_PUBLIC_TRANSPORTATION]?.map((item, index) => (
                          <span key={item.id}>
                              {item.name}
                              {data?.[propertyResponseFields.PROPERTY_PUBLIC_TRANSPORTATION]?.length - 1 !== index ? ', ' : ''}
                          </span>
                      ))
                    : '',
            },
            {
                id: propertyResponseFields.PROPERTY_PERMIT,
                label: t('app.common.property.permits'),
                value: data?.[propertyResponseFields.PROPERTY_PERMIT]?.length
                    ? data?.[propertyResponseFields.PROPERTY_PERMIT]?.map((item, index) => (
                          <span key={item.id}>
                              {item.name}
                              {data?.[propertyResponseFields.PROPERTY_PERMIT]?.length - 1 !== index ? ', ' : ''}
                          </span>
                      ))
                    : '',
            },
            {
                id: propertyResponseFields.PROPERTY_ACCESS_ID,
                label: t('app.common.property.access'),
                value: data?.[propertyResponseFields.PROPERTY_ACCESS_ID]?.length
                    ? data?.[propertyResponseFields.PROPERTY_ACCESS_ID]?.map((item, index) => (
                          <span key={item.id}>
                              {item.name}
                              {data?.[propertyResponseFields.PROPERTY_ACCESS_ID]?.length - 1 !== index ? ', ' : ''}
                          </span>
                      ))
                    : '',
            },
            {
                id: propertyResponseFields.PROPERTY_ENERGY_EFFICIENCY,
                label: t('app.common.energyEfficiency'),
                value: data?.propertyEnergyEfficiency?.name,
            },
        ],
        relatedData: [
            {
                id: propertyResponseFields.PROPERTY_SPECIAL_FEATURE,
                label: t('app.common.property.specialFeatures'),
                value: data?.[propertyResponseFields.PROPERTY_SPECIAL_FEATURE]?.length
                    ? data?.[propertyResponseFields.PROPERTY_SPECIAL_FEATURE]?.map((item, index) => (
                          <span key={item.id}>
                              {item.name}
                              {data?.[propertyResponseFields.PROPERTY_SPECIAL_FEATURE]?.length - 1 !== index ? ', ' : ''}
                          </span>
                      ))
                    : '',
            },
            {
                id: propertyResponseFields.PROPERTY_DESCRIPTIONS,
                label: t('app.common.property.description'),
                value: data?.[propertyResponseFields.PROPERTY_DESCRIPTIONS]?.length
                    ? data?.[propertyResponseFields.PROPERTY_DESCRIPTIONS]?.map((item, index) => (
                          <span key={item.id}>
                              {item.name}
                              {data?.[propertyResponseFields.PROPERTY_DESCRIPTIONS]?.length - 1 !== index ? ', ' : ''}
                          </span>
                      ))
                    : '',
            },
            {
                id: propertyResponseFields.PROPERTY_EQUIPMENT,
                label: t('app.common.property.equipment'),
                value: data?.[propertyResponseFields.PROPERTY_EQUIPMENT]?.length
                    ? data?.[propertyResponseFields.PROPERTY_EQUIPMENT]?.map((item, index) => (
                          <span key={item.id}>
                              {item.name}
                              {data?.[propertyResponseFields.PROPERTY_EQUIPMENT]?.length - 1 !== index ? ', ' : ''}
                          </span>
                      ))
                    : '',
            },
            {
                id: propertyResponseFields.PROPERTY_UTILITY,
                label: t('app.common.utility'),
                value: data?.[propertyResponseFields.PROPERTY_UTILITY]?.length
                    ? data?.[propertyResponseFields.PROPERTY_UTILITY]?.map((item, index) => (
                          <span key={item.id}>
                              {item.name}
                              {data?.[propertyResponseFields.PROPERTY_UTILITY]?.length - 1 !== index ? ', ' : ''}
                          </span>
                      ))
                    : '',
            },
            {
                id: propertyResponseFields.PROPERTY_SPACE,
                label: t('app.common.property.space'),
                value: data?.propertyPropertySpace?.length
                    ? data?.propertyPropertySpace?.map((item, index) => {
                          const spaceAreaArr = item.spaceArea?.split(';')
                          if (spaceAreaArr?.length && spaceAreaArr.length > 1) {
                              return spaceAreaArr.map((areaItem, areaIndex) => (
                                  <div key={`${areaItem}-${areaIndex}`} className="mb-1">
                                      {item.propertySpace?.name} ({areaIndex + 1}) {areaItem}
                                      {unit}
                                  </div>
                              ))
                          }
                          return (
                              <div key={item.id} className="mb-1">
                                  {item.propertySpace?.name} ({item.spaceCount}){' '}
                                  {item.spaceArea && (
                                      <>
                                          {item.spaceArea}
                                          {unit}
                                      </>
                                  )}{' '}
                                  {data?.propertyPropertySpace?.length - 1 !== index ? ', ' : ''}
                              </div>
                          )
                      })
                    : '',
            },
            {
                id: propertyResponseFields.DISTANCE_SEA,
                label: t('app.common.property.seaDistance'),
                value: data?.[propertyResponseFields.DISTANCE_SEA] ? formatNumberWithTwoDecimals(data?.[propertyResponseFields.DISTANCE_SEA]) : '',
            },
            {
                id: propertyResponseFields.DISTANCE_RIVER,
                label: t('app.common.property.riverDistance'),
                value: data?.[propertyResponseFields.DISTANCE_RIVER]
                    ? formatNumberWithTwoDecimals(data?.[propertyResponseFields.DISTANCE_RIVER])
                    : '',
            },
            {
                id: propertyResponseFields.DISTANCE_BEACH,
                label: t('app.common.property.beachDistance'),
                value: data?.[propertyResponseFields.DISTANCE_BEACH]
                    ? formatNumberWithTwoDecimals(data?.[propertyResponseFields.DISTANCE_BEACH])
                    : '',
            },
            {
                id: propertyResponseFields.DISTANCE_CITY_CENTRE,
                label: t('app.common.property.cityCentreDistance'),
                value: data?.[propertyResponseFields.DISTANCE_CITY_CENTRE]
                    ? formatNumberWithTwoDecimals(data?.[propertyResponseFields.DISTANCE_CITY_CENTRE])
                    : '',
            },
            {
                id: propertyResponseFields.DISTANCE_SCHOOL,
                label: t('app.common.property.schoolDistance'),
                value: data?.[propertyResponseFields.DISTANCE_SCHOOL]
                    ? formatNumberWithTwoDecimals(data?.[propertyResponseFields.DISTANCE_SCHOOL])
                    : '',
            },
            {
                id: propertyResponseFields.DISTANCE_PUBLIC_TRANSPORTATION,
                label: t('app.common.property.publicTransportationDistance'),
                value: data?.[propertyResponseFields.DISTANCE_PUBLIC_TRANSPORTATION]
                    ? formatNumberWithTwoDecimals(data?.[propertyResponseFields.DISTANCE_PUBLIC_TRANSPORTATION])
                    : '',
            },
            {
                id: propertyResponseFields.DISTANCE_PATHWAY,
                label: t('app.common.property.pathwayDistance'),
                value: data?.[propertyResponseFields.DISTANCE_PATHWAY]
                    ? formatNumberWithTwoDecimals(data?.[propertyResponseFields.DISTANCE_PATHWAY])
                    : '',
            },
        ],
        other: [
            {
                id: propertyResponseFields.AGENCY_COMMISSION,
                label: t('app.common.agencyComission'),
                value: data?.[propertyResponseFields.AGENCY_COMMISSION]?.name,
            },
            ...getAgentsViewData(data?.[propertyResponseFields.AGENT]),
        ],
    }
}

export const propertyFlatSaleViewData = (data, config) => {
    const { t } = i18next
    const sharedViewData = getSharedViewData(data, config, true)

    if (sharedViewData?.location) {
        sharedViewData.location = [
            ...sharedViewData.location,
            {
                id: propertyResponseFields.PROPERTY_LABEL,
                label: t('app.common.property.designation'),
                value: data?.[propertyResponseFields.PROPERTY_LABEL],
            },
        ]
    }

    return sharedViewData
}

export const propertyFlatLeaseViewData = (data, config) => {
    const { t } = i18next
    const sharedViewData = getSharedViewData(data, config, false)

    if (sharedViewData?.location) {
        sharedViewData.location = [
            ...sharedViewData.location,
            {
                id: propertyResponseFields.PROPERTY_LABEL,
                label: t('app.common.property.designation'),
                value: data?.[propertyResponseFields.PROPERTY_LABEL],
            },
        ]
    }

    if (sharedViewData?.price) {
        const findExchangePossible = sharedViewData.price.findIndex((obj) => obj.id === propertyResponseFields.EXCHANGE_POSSIBLE)
        sharedViewData.price.splice(findExchangePossible, 1)

        sharedViewData.price = [
            ...sharedViewData.price,
            {
                id: propertyResponseFields.UTILITIES_INCLUDED,
                label: t('form.property.general.utilitiesIncluded'),
                value: data?.[propertyResponseFields.UTILITIES_INCLUDED] ? t('view.common.conclusion.yes') : t('view.common.conclusion.no'),
            },
        ]
    }

    return sharedViewData
}

export const propertyHouseSaleViewData = (data, config) => {
    const sharedViewData = getSharedViewData(data, config, true)

    return sharedViewData
}

export const propertyHouseLeaseViewData = (data, config) => {
    const { t } = i18next
    const sharedViewData = getSharedViewData(data, config, false)

    if (sharedViewData?.price) {
        const findExchangePossible = sharedViewData.price.findIndex((obj) => obj.id === propertyResponseFields.EXCHANGE_POSSIBLE)
        sharedViewData.price.splice(findExchangePossible, 1)

        sharedViewData.price = [
            ...sharedViewData.price,
            {
                id: propertyResponseFields.UTILITIES_INCLUDED,
                label: t('form.property.general.utilitiesIncluded'),
                value: data?.[propertyResponseFields.UTILITIES_INCLUDED] ? t('view.common.conclusion.yes') : t('view.common.conclusion.no'),
            },
        ]
    }

    return sharedViewData
}

export const propertyOfficeSpaceSaleViewData = (data, config) => {
    const { t } = i18next
    const sharedViewData = getSharedViewData(data, config, true)

    if (sharedViewData?.location) {
        sharedViewData.location = [
            ...sharedViewData.location,
            {
                id: propertyResponseFields.PROPERTY_LABEL,
                label: t('app.common.property.designation'),
                value: data?.[propertyResponseFields.PROPERTY_LABEL],
            },
        ]
    }

    return sharedViewData
}

export const propertyOfficeSpaceLeaseViewData = (data, config) => {
    const { t } = i18next
    const sharedViewData = getSharedViewData(data, config, false)

    if (sharedViewData?.location) {
        sharedViewData.location = [
            ...sharedViewData.location,
            {
                id: propertyResponseFields.PROPERTY_LABEL,
                label: t('app.common.property.designation'),
                value: data?.[propertyResponseFields.PROPERTY_LABEL],
            },
        ]
    }

    if (sharedViewData?.price) {
        const findExchangePossible = sharedViewData.price.findIndex((obj) => obj.id === propertyResponseFields.EXCHANGE_POSSIBLE)
        sharedViewData.price.splice(findExchangePossible, 1)

        sharedViewData.price = [
            ...sharedViewData.price,
            {
                id: propertyResponseFields.UTILITIES_INCLUDED,
                label: t('form.property.general.utilitiesIncluded'),
                value: data?.[propertyResponseFields.UTILITIES_INCLUDED] ? t('view.common.conclusion.yes') : t('view.common.conclusion.no'),
            },
        ]
    }

    return sharedViewData
}

export const propertyLandSaleViewData = (data, config) => {
    const sharedViewData = getSharedViewData(data, config, true)

    return sharedViewData
}

export const propertyLandLeaseViewData = (data, config) => {
    const { t } = i18next
    const sharedViewData = getSharedViewData(data, config, false)

    if (sharedViewData?.price) {
        const findExchangePossible = sharedViewData.price.findIndex((obj) => obj.id === propertyResponseFields.EXCHANGE_POSSIBLE)
        sharedViewData.price.splice(findExchangePossible, 1)

        sharedViewData.price = [
            ...sharedViewData.price,
            {
                id: propertyResponseFields.UTILITIES_INCLUDED,
                label: t('form.property.general.utilitiesIncluded'),
                value: data?.[propertyResponseFields.UTILITIES_INCLUDED] ? t('view.common.conclusion.yes') : t('view.common.conclusion.no'),
            },
        ]
    }

    return sharedViewData
}

export const propertyGarageSaleViewData = (data, config) => {
    const sharedViewData = getSharedViewData(data, config, true)

    return sharedViewData
}

export const propertyGarageLeaseViewData = (data, config) => {
    const { t } = i18next
    const sharedViewData = getSharedViewData(data, config, false)

    if (sharedViewData?.price) {
        const findExchangePossible = sharedViewData.price.findIndex((obj) => obj.id === propertyResponseFields.EXCHANGE_POSSIBLE)
        sharedViewData.price.splice(findExchangePossible, 1)

        sharedViewData.price = [
            ...sharedViewData.price,
            {
                id: propertyResponseFields.UTILITIES_INCLUDED,
                label: t('form.property.general.utilitiesIncluded'),
                value: data?.[propertyResponseFields.UTILITIES_INCLUDED] ? t('view.common.conclusion.yes') : t('view.common.conclusion.no'),
            },
        ]
    }

    return sharedViewData
}

export const propertyQUickViewData = (data, config) => {
    const currency = renderCurrency(config.currency, false)
    const unit = renderAreaUnit(config?.areaUnit)
    const { t } = i18next

    return {
        general: [
            {
                id: `${propertyResponseFields.ID}_name`,
                label: t('app.common.propertyId'),
                value: (
                    <Link className="dmn-link" to={`/properties/${data?.[propertyResponseFields.ID]}`} target="_blank" rel="noopener noreferrer">
                        {data?.[propertyResponseFields.ID]}
                    </Link>
                ),
            },
            {
                id: `${propertyResponseFields.PROPERTY_SUB_TYPE}_name`,
                label: t('app.common.property.subtype'),
                value: data?.[propertyResponseFields.PROPERTY_SUB_TYPE]?.name,
            },
            {
                id: `${propertyResponseFields.PROPERTY_SERVICE}_name`,
                label: t('app.common.service'),
                value: data?.[propertyResponseFields.PROPERTY_SERVICE]?.name,
            },
            {
                id: `property_location`,
                label: t('app.common.location'),
                value: (
                    <>
                        {data?.[propertyResponseFields.CITY] || data?.[propertyResponseFields.WIDER_AREA] ? (
                            <div>
                                {data?.[propertyResponseFields.CITY] ? `${data?.[propertyResponseFields.CITY]?.name},` : ''}{' '}
                                {data?.[propertyResponseFields.WIDER_AREA] ? `${data?.[propertyResponseFields.WIDER_AREA]?.name}` : ''}
                            </div>
                        ) : null}
                        {}
                        {data?.[propertyResponseFields.QUARTER] ? <div>{data?.[propertyResponseFields.QUARTER]?.name}</div> : ''}
                        {data?.[propertyResponseFields.STREET] ? <div>{data?.[propertyResponseFields.STREET]}</div> : ''}
                    </>
                ),
            },
            {
                id: propertyResponseFields.PRICE,
                label: t('table.header.profile.price'),
                value: data?.[propertyResponseFields.PRICE] ? `${formatNumberWithTwoDecimals(data?.[propertyResponseFields.PRICE])} ${currency}` : '',
            },
            {
                id: propertyResponseFields.AREA,
                label: t('app.common.spaceArea'),
                value: data?.[propertyResponseFields.AREA] ? (
                    <>
                        {formatNumberWithTwoDecimals(data?.[propertyResponseFields.AREA])} {unit}
                    </>
                ) : (
                    ''
                ),
            },
            {
                id: propertyResponseFields.PROPERTY_ROOMS_NO,
                label: t('form.diary.roomNo'),
                value: data?.[propertyResponseFields.PROPERTY_ROOMS_NO],
            },
            {
                id: propertyResponseFields.FLOOR_NO,
                label: `${t('app.common.floor')} / ${t('app.common.property.floorsTotal')}`,
                value:
                    data?.[propertyResponseFields.FLOOR_NO] && data?.[propertyResponseFields.FLOORS_TOTAL_NO] ? (
                        <>
                            {data?.[propertyResponseFields.FLOOR_NO]}/{data?.[propertyResponseFields.FLOORS_TOTAL_NO]}
                        </>
                    ) : (
                        ''
                    ),
            },
        ],
    }
}
