import { useEffect, useState } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import {
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    Badge,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Collapse,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    NavItem,
    Row,
    TabContent,
    TabPane,
    UncontrolledAccordion,
    UncontrolledDropdown,
} from 'reactstrap'
import ActivitiesViewAccordion from '../../Components/ActivitiesViewAccordion'
import AppPermissionsSwitcher from '../../Components/AppPermissionsSwitcher/AppPermissionsSwitcher'
import { action } from '../../Components/AppPermissionsSwitcher/constants/actions'
import AppTopSwitcher from '../../Components/AppTopSwitcher/AppTopSwitcher'
import BackButton from '../../Components/BackButton/BackButton'
import DeleteModal from '../../Components/Common/DeleteModal'
import ErrorModal from '../../Components/Common/ErrorModal'
import FormTabNavigation from '../../Components/Common/FormTabNavigation'
import Loader from '../../Components/Common/Loader'
import NavigationActions from '../../Components/Common/NavigationActions/NavigationActions'
import PageTitle from '../../Components/Common/PageTitle'
import ContactProfileViewTab from '../../Components/ContactProfileViewTab'
import DocumentViewTab from '../../Components/DocumentViewTab/Index'
import NotesViewAccordion from '../../Components/NotesViewAccordion'
import { CONTACT_TYPE, SUBMIT_RESPONSE_STATUS } from '../../common/constants'
import { companyResponseFields } from '../../common/response/company'
import { useCompanyViewTabs } from '../../hooks'
import { useColumnOrder } from '../../hooks/useColumnOrder'
import { useCompanyGeneralViewData } from '../../hooks/useCompanyViewData'
import { selectCurrentUser } from '../../store/auth/selectors'
import {
    deleteCompany,
    fetchCompanies,
    fetchCompany,
    selectCompanies,
    selectCompaniesFetching,
    selectCompaniesPagination,
    selectCompany,
    selectCompanyFetchingDetails,
    selectCompanyLinks,
} from '../../store/companies'
import { fetchContactProfiles, selectContactProfiles } from '../../store/contactProfiles'
import { selectSearchCriteriaCompanies } from '../../store/search'
import { formatDateTime } from '../../utils/general'

const CompanyView = () => {
    const history = useHistory()
    const location = useLocation()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { id } = useParams()

    const { company, fetching, contactProfile, companySearchCriteria, currentAgentId, links } = useSelector((state) => ({
        company: selectCompany(state),
        companies: selectCompanies(state),
        fetching: selectCompanyFetchingDetails(state),
        companiesFetching: selectCompaniesFetching(state),
        contactProfile: selectContactProfiles(state),
        pagination: selectCompaniesPagination(state),
        companySearchCriteria: selectSearchCriteriaCompanies(state),
        currentAgentId: selectCurrentUser(state).id,
        links: selectCompanyLinks(state),
    }))

    const tabs = useCompanyViewTabs()
    const viewData = useCompanyGeneralViewData(company)
    const companyOrder = useColumnOrder('company')

    const [activeTab, setActiveTab] = useState(null)
    const [noteErrorModal, setNoteErrorModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)

    useEffect(() => {
        const payload = companySearchCriteria
            ? { id, order: companyOrder, criteria: { ...companySearchCriteria, contactType: 'company' } }
            : { id, order: companyOrder }

        dispatch(fetchCompany(payload))
        dispatch(fetchContactProfiles(id))
    }, [dispatch, companySearchCriteria, companyOrder, id])

    useEffect(() => {
        if (tabs.length > 0) {
            setActiveTab(tabs[0].name)
        }
    }, [tabs])

    const handleTabChange = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }

    const handleDeleteModal = () => {
        setDeleteModal(true)
    }

    const handleDeleteContact = () => {
        dispatch(deleteCompany(id, () => history.push('/companies')))
        setDeleteModal(false)
    }

    if (fetching) {
        return (
            <div className="page-content">
                <Container fluid>
                    <Loader />
                </Container>
            </div>
        )
    }

    document.title = `${company?.[companyResponseFields.CONTACT_COMPANY]?.[companyResponseFields.NAME] ?? t('app.common.companies')} | ${t(
        'app.title'
    )}`

    return (
        <div className="page-content">
            <Container fluid>
                <div className="page-title-box ps-0 d-sm-flex align-items-start mt-3">
                    <BackButton className="me-3" />
                    <div>
                        <PageTitle
                            className="px-2"
                            title={`${company?.[companyResponseFields.CONTACT_COMPANY]?.[companyResponseFields.NAME] ?? ''}`}
                            titleBadgeComponent={<Badge color="favorite">{company?.[companyResponseFields.CONTACT_PRIORITY]?.name}</Badge>}
                        />

                        {company?.contactStatus && (
                            <Row>
                                <Col sm="12">
                                    <div className="px-2">
                                        <span className="fw-medium">{t('app.common.contact.profileStatus')}</span>
                                        {company?.[companyResponseFields.CONTACT_STATUS]?.name}
                                    </div>
                                </Col>
                            </Row>
                        )}

                        <Row>
                            <Col sm="12">
                                <div className="px-2">
                                    <span className="fw-medium">{t('app.common.contact.establishmentDate')}:</span>{' '}
                                    {formatDateTime(company?.[companyResponseFields.INITIAL_CONTACT_DATE])}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>

                <Row className="d-flex justify-content-between align-items-center mt-5 mb-3">
                    <Col sm={12} md={8}>
                        <FormTabNavigation tabs={tabs} activeTab={activeTab} handleTabChange={handleTabChange} customClass="detail-view-tabs">
                            <NavItem>
                                <UncontrolledDropdown>
                                    <DropdownToggle
                                        title={t('table.showMore')}
                                        href="#"
                                        className="real-estate-form-nav-link nav-link dropdown"
                                        tag="a"
                                    >
                                        <i className="ri-more-fill align-middle"></i>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end">
                                        <AppPermissionsSwitcher permission="ModulePermissions" module={location} action={action.edit}>
                                            {({ hasModulePermission }) =>
                                                hasModulePermission && (
                                                    <>
                                                        <DropdownItem onClick={() => history.push(`/companies/${id}/edit`)}>
                                                            <i className="mdi mdi-pencil-outline align-bottom me-2 text-muted"></i>{' '}
                                                            {t('view.company.action.edit')}
                                                        </DropdownItem>
                                                        <DropdownItem href="#" onClick={() => handleDeleteModal()}>
                                                            <i className="mdi mdi-trash-can-outline align-bottom me-2 text-muted"></i>{' '}
                                                            {t('view.company.action.delete')}
                                                        </DropdownItem>
                                                    </>
                                                )
                                            }
                                        </AppPermissionsSwitcher>
                                        <DropdownItem
                                            onClick={() =>
                                                history.push({
                                                    pathname: `/companies/${id}/edit`,
                                                    state: {
                                                        activeTab: 'contactProfile',
                                                        profileId: 0,
                                                    },
                                                })
                                            }
                                        >
                                            <i className="mdi mdi-plus align-bottom me-2 text-muted"></i> {t('view.contact.action.add')}
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </NavItem>
                        </FormTabNavigation>
                    </Col>
                    <Col sm={12} md={4}>
                        <NavigationActions links={links} />
                    </Col>
                </Row>
                <TabContent activeTab={activeTab} className="text-muted">
                    {activeTab === 'general' && (
                        <TabPane tabId="general">
                            <Row>
                                <Col sm={12} xxl={3}>
                                    <Card>
                                        <CardBody>
                                            {viewData?.general &&
                                                viewData.general
                                                    .filter((viewItem) => viewItem.value)
                                                    .map((viewItem, index) => {
                                                        if (viewItem.value) {
                                                            return (
                                                                <Row
                                                                    className={`align-items-start ${
                                                                        viewData.general.filter((x) => x.value).length - 1 !== index ? 'mb-4' : ''
                                                                    }`}
                                                                    key={viewItem?.id}
                                                                >
                                                                    <Col sm={6}>
                                                                        <span className="flex-grow-1 fw-medium">{viewItem?.label}:</span>
                                                                    </Col>
                                                                    <Col sm={6}>
                                                                        <span className="flex-grow-1 fw-light">{viewItem?.value}</span>
                                                                    </Col>
                                                                </Row>
                                                            )
                                                        }
                                                        return null
                                                    })}
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm={12} xxl={9}>
                                    <UncontrolledAccordion defaultOpen={['1']} stayOpen>
                                        <AccordionItem className="mb-4">
                                            <AccordionHeader targetId="1">
                                                <h5>{t('view.company.queryData')}</h5>
                                            </AccordionHeader>
                                            <AccordionBody accordionId="1">
                                                <Row className="mt-4">
                                                    <Col sm={12} className="fw-light text-secondary">
                                                        {company?.[companyResponseFields.NOTES]}
                                                    </Col>
                                                </Row>
                                            </AccordionBody>
                                        </AccordionItem>
                                    </UncontrolledAccordion>

                                    <NotesViewAccordion id={id} noteEntity={'contact'} currentAgentId={currentAgentId} />

                                    <AppTopSwitcher
                                        feature="ActivitiesAccordion"
                                        permission="ModulePermissions"
                                        permissionName={'module.activity.view'}
                                        module={location}
                                        action={action.view}
                                    >
                                        {(props) => {
                                            const { featureActivitiesAccordion, hasModulePermission: permissionActivitiesAccordion } = props
                                            const allowAccordion = featureActivitiesAccordion && permissionActivitiesAccordion

                                            return (
                                                allowAccordion && (
                                                    <ActivitiesViewAccordion
                                                        contactType={CONTACT_TYPE.COMPANY}
                                                        parentId={id}
                                                        contact={company}
                                                        module={location}
                                                    />
                                                )
                                            )
                                        }}
                                    </AppTopSwitcher>
                                </Col>
                            </Row>
                        </TabPane>
                    )}
                    {activeTab === 'contactProfile' && (
                        <TabPane tabId="contactProfile">
                            <ContactProfileViewTab id={id} profiles={contactProfile} contactType={CONTACT_TYPE.COMPANY} />
                        </TabPane>
                    )}
                    {activeTab === 'documents' && (
                        <TabPane tabId="documents">
                            <Card>
                                <CardBody>
                                    <DocumentViewTab
                                        documentModule="contact"
                                        contactType={CONTACT_TYPE.COMPANY}
                                        addButtonAction={() => {
                                            history.push({
                                                pathname: `/companies/${id}/edit`,
                                                state: { activeTab: 'documents' },
                                            })
                                        }}
                                    />
                                </CardBody>
                            </Card>
                        </TabPane>
                    )}
                </TabContent>
            </Container>

            <ErrorModal show={noteErrorModal} onCloseClick={() => setNoteErrorModal(false)} title="Uups!">
                <p>{t('modal.error.message')}</p>
            </ErrorModal>

            <DeleteModal
                show={deleteModal}
                module={'company'}
                onDeleteClick={() => handleDeleteContact()}
                onCloseClick={() => setDeleteModal(false)}
            />
        </div>
    )
}

export default withTranslation()(CompanyView)
