import { getIn } from 'formik'
import { isEqual } from 'lodash'
import { useContext, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Row } from 'reactstrap'
import { realEstateGeneralFields } from '../../common/forms/generalRealEstate/fields'
import { fetchLocationsCity, fetchLocationsCounty, fetchLocationsIsland, fetchLocationsQuarter, fetchLocationsWiderArea } from '../../store/locations'
import AppPermissionsSwitcher from '../AppPermissionsSwitcher/AppPermissionsSwitcher'
import FormMainContainer from '../Common/FormMainContainer'
import FormSideContainer from '../Common/FormSideContainer'
import RecursiveForm from '../RecursiveForm'
import ProjectFormGeneralContext from './ProjectFormGeneralContext'

const ProjectFormGeneralTab = ({ children, ...props }) => {
    const id = getIn(props.formik?.values?.general, 'id')

    return (
        <ProjectFormGeneralContext.Provider
            value={{
                formik: props.formik,
                inputs: props.inputs,
                subform: props.subform,
                selectOptions: props.selectOptions,
                inputComponents: props.inputComponents,
                tabs: props.tabs,
                activeTab: props.activeTab,
                handleTabChange: props.handleTabChange,
                module: props.module,
                id,
            }}
        >
            <Row>{children}</Row>
        </ProjectFormGeneralContext.Provider>
    )
}

export const ProjectFormGeneralTabSide = (props) => {
    const { inputs, formik, subform, selectOptions, inputComponents, module } = useContext(ProjectFormGeneralContext)

    return (
        <FormSideContainer>
            <AppPermissionsSwitcher permission="EditData" data={inputs?.sideForm} module={module}>
                {({ newData }) => (
                    <RecursiveForm
                        inputs={newData}
                        subform={subform}
                        formik={formik}
                        selectOptions={selectOptions}
                        inputComponents={inputComponents}
                    />
                )}
            </AppPermissionsSwitcher>
        </FormSideContainer>
    )
}

export const ProjectFormGeneralTabMain = ({ children, ...props }) => {
    const { inputs, formik, subform, selectOptions, inputComponents, tabs, activeTab, handleTabChange, id, module } =
        useContext(ProjectFormGeneralContext)

    const dispatch = useDispatch()
    const [currentFormValues, setCurrentFormValues] = useState({})

    const countryId = getIn(formik?.values?.general, realEstateGeneralFields.COUNTRY_ID)
    const countyId = getIn(formik?.values?.general, realEstateGeneralFields.COUNTY_ID)
    const cityId = getIn(formik?.values?.general, realEstateGeneralFields.CITY_ID)
    const widerAreaId = getIn(formik?.values?.general, realEstateGeneralFields.WIDER_AREA_ID)

    // fetch select options
    useEffect(() => {
        const formConfig = [
            { label: 'country_id', value: countryId, action: fetchLocationsCounty, nextAction: fetchLocationsIsland },
            { label: 'county_id', value: countyId, action: fetchLocationsCity },
            { label: 'city_id', value: cityId, action: fetchLocationsWiderArea },
            { label: 'wider_area_id', value: widerAreaId, action: fetchLocationsQuarter },
        ]

        formConfig.forEach((config) => {
            const { label, value, action, nextAction } = config

            if (value !== '' && value !== currentFormValues[label]) {
                setCurrentFormValues((prev) => ({ ...prev, [label]: value }))

                dispatch(action(value))
                if (nextAction) dispatch(nextAction(value))
            }
        })
    }, [countryId, countyId, cityId, widerAreaId, currentFormValues, dispatch])

    // reset fields
    useEffect(() => {
        const fieldsToReset = [
            realEstateGeneralFields.COUNTY_ID,
            realEstateGeneralFields.CITY_ID,
            realEstateGeneralFields.WIDER_AREA_ID,
            realEstateGeneralFields.QUARTER_ID,
            realEstateGeneralFields.ISLAND_ID,
        ]

        const resetConfig = [
            { label: 'country_id', value: countryId, sliceIndex: 0 },
            { label: 'county_id', value: countyId, sliceIndex: 1 },
            { label: 'city_id', value: cityId, sliceIndex: 2 },
            { label: 'wider_area_id', value: widerAreaId, sliceIndex: 3 },
        ]

        const hasValidFormValues =
            Object.keys(currentFormValues).length > 0 && Object.values(currentFormValues).every((value) => value !== '')

        if (!hasValidFormValues) return

        resetConfig.forEach((config) => {
            const { label, value, sliceIndex } = config

            if (value !== '' && !isEqual(+value, +currentFormValues[label])) {
                fieldsToReset.slice(sliceIndex).forEach((field) => {
                    formik.setFieldValue(`${subform}.${field}`, '')
                })
            }
        })
    }, [countryId, countyId, cityId, widerAreaId, currentFormValues])


    return (
        <FormMainContainer tabs={tabs} activeTab={activeTab} handleTabChange={handleTabChange} formik={formik}>
            <AppPermissionsSwitcher permission="EditData" data={inputs?.mainForm} module={module}>
                {({ newData }) => (
                    <RecursiveForm
                        inputs={newData}
                        subform={subform}
                        formik={formik}
                        selectOptions={selectOptions}
                        inputComponents={inputComponents}
                    />
                )}
            </AppPermissionsSwitcher>
        </FormMainContainer>
    )
}

export default ProjectFormGeneralTab
