import { FormikProvider, getIn, useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Container, Form, TabContent, TabPane } from 'reactstrap'
import * as Yup from 'yup'
import FormActionButtons from '../../Components/Common/FormActionButtons'
import PageTitle from '../../Components/Common/PageTitle'
import PropertyFormGeneralTab, {
    PropertyFormGeneralTabMain,
    PropertyFormGeneralTabSide,
} from '../../Components/PropertyFormGeneralTab/PropertyFormGeneralTab'
import {
    useMapFormConfig,
    usePropertyExportFormConfig,
    useRealEstateDescriptionFormConfig,
    useRealEstateGeneralFormConfig,
    useRealEstateGeneralFormSelectOptions,
} from '../../Components/Hooks/FormHooks'
import { realEstateGeneralFields } from '../../common/forms/generalRealEstate/fields'

import { useTranslation } from 'react-i18next'
import { setPropertyStatus } from '../../Components/AppPermissionsSwitcher/utils/propertyStatus'
import Loader from '../../Components/Common/Loader'
import MapFormTab, { MapFormTabMain, MapFormTabSide } from '../../Components/MapFormTab/MapFormTab'
import FormValidationErrorModal from '../../Components/Modals/FormValidationErrorModal'
import PropertyFormDescriptionTab, {
    PropertyFormDescriptionTabMain,
    PropertyFormDescriptionTabSide,
} from '../../Components/PropertyFormDescriptionTab/PropertyFormDescriptionTab'
import PropertyFormExportTab, {
    PropertyFormExportTabMain,
    PropertyFormExportTabSide,
} from '../../Components/PropertyFormExportTab/PropertyFormExportTab'
import { propertyResponseFields } from '../../common/response/property'
import { selectCurrentUser } from '../../store/auth/selectors'
import { clearGroundPlans } from '../../store/groundplans'
import { clearPhotos } from '../../store/photos'
import properties from '../../api/properties/hooks'
import { propertyGeneralFormTabs } from './constants'

const PropertyCreate = () => {
    const history = useHistory()
    const { t } = useTranslation()
    const { id } = useParams()

    const isClone = typeof id === 'string'
    const tabs = propertyGeneralFormTabs(false, isClone)

    const [activeTab, setActiveTab] = useState(tabs[0].name)
    const [realEstateType, setRealEstateType] = useState(null)
    const [realEstateService, setRealEstateService] = useState(null)
    const [isErrorModalActive, setIsErrorModalActive] = useState(false)

    const { isLoading, data } = id ? properties.GetProperty.useQuery(id) : { isLoading: null, data: null }
    const { mutate: createUpdateMutate } = properties.CreateUpdateProperty.useMutation(id, false)

    const property = !isLoading
        ? {
              ...data?.item,
              id: null,
              [propertyResponseFields.INTERNAL_ID]: null,
          }
        : null

    let { agent } = useSelector((state) => ({
        agent: selectCurrentUser(state),
    }))

    const { defaultCountry, defaultCounty, defaultCity, agentPermissions } = agent

    const propertyFormGeneralTabLocationData = {
        country: defaultCountry,
        county: defaultCounty,
        city: defaultCity,
    }

    const { formInputs, formikConfig } = useRealEstateGeneralFormConfig(
        isClone ? 1 : 0,
        isClone ? property?.propertyType?.id : realEstateType,
        isClone ? property?.propertyService?.id : realEstateService,
        isClone ? property : null
    )
    const { descriptionInputs, descriptionValidationConfig } = useRealEstateDescriptionFormConfig(isClone ? property : null)
    const { mapInputs, mapValidationConfig } = useMapFormConfig(isClone ? property : null)
    const { exportInputs, exportValidationConfig } = usePropertyExportFormConfig(isClone ? property : null)
    const formSelectOptions = useRealEstateGeneralFormSelectOptions()

    const handleTabChange = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }

    const { isValid, isSubmitting, isValidating, ...validation } = useFormik({
        enableReinitialize: true,
        initialValues: {
            description: descriptionValidationConfig.initialValues ?? {},
            general:
                (isClone
                    ? {
                          ...formikConfig.initialValues,
                          [realEstateGeneralFields.PRICE_OLD]: '',
                      }
                    : formikConfig.initialValues) ?? {},
            ...(isClone
                ? {
                      map: mapValidationConfig.initialValues ?? {},
                      export: exportValidationConfig.initialValues ?? {},
                  }
                : {}),
        },
        validationSchema: Yup.object({
            general: formikConfig.validationSchema ?? Yup.object({}),
            description: descriptionValidationConfig.validationSchema ?? Yup.object({}),
            ...(isClone
                ? {
                      map: mapValidationConfig.validationSchema ?? Yup.object({}),
                      export: exportValidationConfig.validationSchema ?? Yup.object({}),
                  }
                : {}),
        }),
        onSubmit: (values) => {
            const generalValues = formikConfig.prepareModelForSubmit(values.general)
            const { defaultStatus } = setPropertyStatus({ agentPermissions })

            const submitValues = {
                ...generalValues,
                ...values.description,
                ...(isClone
                    ? {
                          ...values.map,
                          ...values.export,
                      }
                    : {}),
                property_status_id: defaultStatus,
                agent_id: [values.general.agent_id, values.general.agent_id_secondary],
                [realEstateGeneralFields.PRICE]: values?.general[realEstateGeneralFields.PRICE]?.toString().replace(/\./g, '').replace(/,/g, '.'),
                [realEstateGeneralFields.PRICE_INITIAL]: values?.general[realEstateGeneralFields.PRICE_INITIAL]
                    ?.toString()
                    .replace(/\./g, '')
                    .replace(/,/g, '.'),
                [realEstateGeneralFields.PRICE_CLIENT]: values?.general[realEstateGeneralFields.PRICE_CLIENT]
                    ?.toString()
                    .replace(/\./g, '')
                    .replace(/,/g, '.'),
                [realEstateGeneralFields.PRICE_M2]: values?.general[realEstateGeneralFields.PRICE_M2]
                    ?.toString()
                    .replace(/\./g, '')
                    .replace(/,/g, '.'),
                [realEstateGeneralFields.PRICE_OLD]: values?.general[realEstateGeneralFields.PRICE_OLD]
                    ?.toString()
                    .replace(/\./g, '')
                    .replace(/,/g, '.'),
            }
            delete submitValues.id
            delete submitValues.agent_id_secondary
            submitValues.agent_id = submitValues.agent_id.filter(Boolean)

            /*             dispatch(
                createProperty(submitValues, (response) => {
                    if (response.submitStatus === SUBMIT_RESPONSE_STATUS.SUCCESS) {
                        history.push(`/properties/${response.data.id}/edit`)
                    }
                })
            ) */
            createUpdateMutate(submitValues)

            // clear previously fetched photos from the store
            //dispatch(clearPhotos())
            //dispatch(clearGroundPlans())
        },
    })
    const realEstateTypeValue = getIn(validation.values?.general, realEstateGeneralFields.PROPERTY_TYPE_ID)
    const realEstateServiceValue = getIn(validation.values?.general, realEstateGeneralFields.PROPERTY_SERVICE_ID)

    useEffect(() => {
        if (realEstateTypeValue !== realEstateType) {
            setRealEstateType(realEstateTypeValue)
        }
    }, [realEstateTypeValue, realEstateType])

    useEffect(() => {
        if (realEstateServiceValue !== realEstateService) {
            setRealEstateService(realEstateServiceValue)
        }
    }, [realEstateServiceValue, realEstateService])

    useEffect(() => {
        if (!isValid && !isValidating && isSubmitting) {
            setIsErrorModalActive(true)
        }
    }, [isValid, isValidating, isSubmitting])

    const renderFormButtons = () => <FormActionButtons disabled={false} hasCancel={true} cancelAction={() => history.push('/properties')} />

    const toggleErrorModal = () => {
        setIsErrorModalActive(!isErrorModalActive)
    }

    if (isClone && isLoading) {
        return (
            <div className="page-content">
                <Container fluid>
                    <Loader />
                </Container>
            </div>
        )
    }

    return (
        <div className="page-content">
            <Container fluid>
                <FormikProvider value={validation}>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                        }}
                        className="needs-validation"
                        id="real_estate_form"
                    >
                        <PageTitle title={t('form.property.create.title')}>{renderFormButtons()}</PageTitle>

                        <TabContent activeTab={activeTab} className="text-muted">
                            <TabPane tabId="general">
                                <PropertyFormGeneralTab
                                    tabs={tabs}
                                    activeTab={activeTab}
                                    handleTabChange={handleTabChange}
                                    inputs={formInputs}
                                    subform="general"
                                    formik={validation}
                                    selectOptions={formSelectOptions}
                                    realEstateType={realEstateType}
                                    realEstateService={realEstateService}
                                    locationData={propertyFormGeneralTabLocationData}
                                    action={'create'}
                                >
                                    <PropertyFormGeneralTabSide />
                                    <PropertyFormGeneralTabMain />
                                </PropertyFormGeneralTab>
                            </TabPane>

                            <TabPane tabId="description">
                                <PropertyFormDescriptionTab
                                    tabs={tabs}
                                    activeTab={activeTab}
                                    handleTabChange={handleTabChange}
                                    inputs={descriptionInputs}
                                    subform="description"
                                    formik={validation}
                                    realEstateType={realEstateType}
                                    realEstateService={realEstateService}
                                >
                                    <PropertyFormDescriptionTabSide />
                                    <PropertyFormDescriptionTabMain />
                                </PropertyFormDescriptionTab>
                            </TabPane>

                            {isClone && (
                                <>
                                    <TabPane tabId="map">
                                        <MapFormTab
                                            tabs={tabs}
                                            activeTab={activeTab}
                                            handleTabChange={handleTabChange}
                                            inputs={mapInputs}
                                            formik={validation}
                                        >
                                            <MapFormTabSide />
                                            <MapFormTabMain />
                                        </MapFormTab>
                                    </TabPane>
                                    <TabPane tabId="export">
                                        <PropertyFormExportTab
                                            tabs={tabs}
                                            activeTab={activeTab}
                                            handleTabChange={handleTabChange}
                                            subform="export"
                                            formik={validation}
                                        >
                                            <PropertyFormExportTabSide />
                                            <PropertyFormExportTabMain />
                                        </PropertyFormExportTab>
                                    </TabPane>
                                </>
                            )}
                        </TabContent>
                    </Form>
                </FormikProvider>
            </Container>
            {isErrorModalActive && (
                <FormValidationErrorModal
                    validation={validation}
                    inputs={[formInputs, descriptionInputs, mapInputs, exportInputs]}
                    onClose={() => toggleErrorModal(false)}
                />
            )}
        </div>
    )
}

export default PropertyCreate
