import i18next from 'i18next'
import { fetchContactsAsyncInput } from '../../../store/contacts'
import { fetchProjectsAsyncInput } from '../../../store/projects/actions'
import { renderAreaUnit, renderCurrency, renderCurrencyWithUnit } from '../../../utils/property'
import responseFields from '../../constants/responseFields'
import {
    baseAsyncSelectInput,
    baseCheckboxInput,
    baseDateInput,
    baseEmailInput,
    baseMultiSelectInput,
    baseNumberInput,
    basePriceInput,
    baseSelectInput,
    baseTextAreaInput,
    baseTextInput,
    phoneNumber,
} from '../fieldTypes'
import {
    contactDetailSearchFields,
    contactDocumentFields,
    contactGeneralFields,
    contactNoteFields,
    contactOtherInfoFields,
    contactProfileFields,
} from './fields'

export const contactGeneralFieldTypes = (isEdit) => {
    const { t } = i18next

    return {
        [contactGeneralFields.ID]: {
            ...baseTextInput,
            name: contactGeneralFields.ID,
            label: t('form.contact.general.id'),
            disabled: true,
            colProps: {
                sm: 12,
            },
        },
        [contactGeneralFields.CONTACT_TITLE_ID]: {
            ...baseSelectInput,
            name: contactGeneralFields.CONTACT_TITLE_ID,
            label: t('form.contact.title'),
            colProps: {
                sm: 12,
            },
        },
        [contactGeneralFields.FIRST_NAME]: {
            ...baseTextInput,
            name: contactGeneralFields.FIRST_NAME,
            label: t('app.common.firstName'),
            required: true,
            colProps: {
                sm: 12,
            },
        },
        [contactGeneralFields.MIDDLE_NAME]: {
            ...baseTextInput,
            name: contactGeneralFields.MIDDLE_NAME,
            label: t('form.contact.general.middleName'),
            colProps: {
                sm: 12,
            },
        },
        [contactGeneralFields.LAST_NAME]: {
            ...baseTextInput,
            name: contactGeneralFields.LAST_NAME,
            label: t('app.common.lastName'),
            required: true,
            colProps: {
                sm: 12,
            },
        },
        [contactGeneralFields.INITIAL_CONTACT_DATE]: {
            ...baseDateInput,
            name: contactGeneralFields.INITIAL_CONTACT_DATE,
            label: t('app.common.contact.establishmentDate'),
            colProps: {
                sm: 12,
            },
        },
        [contactGeneralFields.CONTACT_PRIORITY_ID]: {
            ...baseSelectInput,
            name: contactGeneralFields.CONTACT_PRIORITY_ID,
            label: t('form.contact.general.priority'),
            colProps: {
                sm: 12,
            },
        },
        [contactGeneralFields.DATA_SOURCE_ID]: {
            ...baseSelectInput,
            name: contactGeneralFields.DATA_SOURCE_ID,
            label: t('app.common.contact.dataSource'),
            colProps: {
                sm: 12,
            },
        },
        [contactGeneralFields.CONTACT_SOURCE_ID]: {
            ...baseSelectInput,
            name: contactGeneralFields.CONTACT_SOURCE_ID,
            label: t('form.contact.general.contactSource'),
            colProps: {
                sm: 12,
            },
        },
        [contactGeneralFields.PHONE_NUMBER]: {
            ...phoneNumber,
            name: contactGeneralFields.PHONE_NUMBER,
            label: t('app.common.phoneNumber'),
        },
        [contactGeneralFields.CONTACT_PHONE_TYPE_ID]: {
            ...baseSelectInput,
            name: contactGeneralFields.CONTACT_PHONE_TYPE_ID,
            label: t('form.contact.general.phoneType'),
        },
        [contactGeneralFields.EMAIL]: {
            ...baseTextInput,
            name: contactGeneralFields.EMAIL,
            label: t('app.common.email.label'),
        },
        [contactGeneralFields.CONTACT_EMAIL_TYPE_ID]: {
            ...baseSelectInput,
            name: contactGeneralFields.CONTACT_EMAIL_TYPE_ID,
            label: t('form.contact.general.emailType'),
        },
        [contactGeneralFields.NEWSLETTER_APPROVAL]: {
            ...baseCheckboxInput,
            name: contactGeneralFields.NEWSLETTER_APPROVAL,
            label: t('form.contact.general.newsletter.label'),
            tooltip: t('form.contact.general.newsletter.tooltip'),
        },
        [contactGeneralFields.STREET]: {
            ...baseTextInput,
            name: contactGeneralFields.STREET,
            label: t('app.common.street'),
        },
        [contactGeneralFields.STREET_NUMBER]: {
            ...baseTextInput,
            name: contactGeneralFields.STREET_NUMBER,
            label: t('app.common.streetNumber'),
        },
        [contactGeneralFields.ZIP_CODE]: {
            ...baseTextInput,
            name: contactGeneralFields.ZIP_CODE,
            label: t('form.contact.general.zipCode'),
        },
        [contactGeneralFields.CITY]: {
            ...baseTextInput,
            name: contactGeneralFields.CITY,
            label: t('app.common.cityMunicipality'),
        },
        [contactGeneralFields.CONTACT_COUNTRY_ID]: {
            ...baseSelectInput,
            name: contactGeneralFields.CONTACT_COUNTRY_ID,
            label: t('app.common.country'),
        },
        // [contactGeneralFields.ADDITIONAL_CONTACT_ID]: {
        //     ...baseTextInput,
        //     name: contactGeneralFields.ADDITIONAL_CONTACT_ID,
        //     label: t('Kontakt osoba'),
        // },
        [contactGeneralFields.CONTACT_STATUS_ID]: {
            ...baseSelectInput,
            name: contactGeneralFields.CONTACT_STATUS_ID,
            label: t('app.common.status'),
            colProps: {
                sm: 12,
            },
        },
        [contactGeneralFields.AGENT_ID]: {
            ...baseSelectInput,
            name: contactGeneralFields.AGENT_ID,
            label: t('app.common.agent'),
            required: true,
        },
        [contactGeneralFields.CONTACT_QUERY_CATEGORY_ID]: {
            ...baseSelectInput,
            name: contactGeneralFields.CONTACT_QUERY_CATEGORY_ID,
            label: t('app.common.contact.queryCategory'),
            // colProps: {
            //     sm: 12
            // }
        },
        [contactGeneralFields.CONTACT_ABOUT]: {
            ...baseTextInput,
            name: contactGeneralFields.CONTACT_ABOUT,
            label: t('app.common.contact.about'),
            colProps: {
                sm: 12,
            },
        },
    }
}

export const contactOtherInfoFieldTypes = (isEdit, appSettings) => {
    const { t } = i18next

    return {
        [contactOtherInfoFields.CONTACT_GENDER_ID]: {
            ...baseSelectInput,
            name: contactOtherInfoFields.CONTACT_GENDER_ID,
            label: t('app.common.gender'),
            colProps: {
                sm: 12,
            },
        },
        [contactOtherInfoFields.OIB]: {
            ...baseNumberInput,
            name: contactOtherInfoFields.OIB,
            label: t('app.common.pin'),
            colProps: {
                sm: 12,
            },
        },
        // [contactOtherInfoFields.CONTACT_DEFINITION_ID]: {
        //     ...baseSelectInput,
        //     name: contactOtherInfoFields.CONTACT_DEFINITION_ID,
        //     label: t('Definicija kontakta'),
        // },
        [contactOtherInfoFields.CONTACT_DESCRIPTION_ID]: {
            ...baseSelectInput,
            name: contactOtherInfoFields.CONTACT_DESCRIPTION_ID,
            label: t('app.common.contact.description'),
            colProps: {
                sm: 12,
            },
        },
        [contactOtherInfoFields.COMPANY_ID]: {
            ...baseAsyncSelectInput,
            name: contactOtherInfoFields.COMPANY_ID,
            label: t('app.common.company.label'),
            criteria: ['name'],
            action: fetchContactsAsyncInput,
            staticCriteria: {
                contactType: 'company',
            },
            noLimit: true,
            tooltip: t('form.contact.other.company.tooltip'),
        },
        [contactOtherInfoFields.CONTACT_JOB_TITLE_ID]: {
            ...baseSelectInput,
            name: contactOtherInfoFields.CONTACT_JOB_TITLE_ID,
            label: t('app.common.jobTitle'),
        },
        [contactOtherInfoFields.EMPLOYMENT_TYPE]: {
            ...baseTextInput,
            name: contactOtherInfoFields.EMPLOYMENT_TYPE,
            label: t('app.common.contact.employmentType'),
        },
        [contactOtherInfoFields.BIRTH_DATE]: {
            ...baseDateInput,
            name: contactOtherInfoFields.BIRTH_DATE,
            label: t('app.common.contact.birthDate'),
        },
        [contactOtherInfoFields.CONTACT_MARTIAL_STATUS_ID]: {
            ...baseSelectInput,
            name: contactOtherInfoFields.CONTACT_MARTIAL_STATUS_ID,
            label: t('app.common.contact.maritalStatus'),
        },
        [contactOtherInfoFields.INITIAL_CALL_INFO]: {
            ...baseTextAreaInput,
            name: contactOtherInfoFields.INITIAL_CALL_INFO,
            label: t('form.contact.other.initialCallInfo'),
            colProps: {
                sm: 12,
            },
            inputProps: {
                style: { resize: 'none', height: '170px' },
            },
        },
        [contactOtherInfoFields.NOTES]: {
            ...baseTextAreaInput,
            name: contactOtherInfoFields.NOTES,
            label: t('app.common.remark'),
            colProps: {
                sm: 12,
            },
            inputProps: {
                style: { resize: 'none', height: '170px' },
            },
        },
    }
}

export const contactProfileFieldTypes = (isEdit, appSettings) => {
    const { t } = i18next

    return {
        [contactProfileFields.CONTACT_PROFILE_SERVICE_ID]: {
            ...baseSelectInput,
            name: contactProfileFields.CONTACT_PROFILE_SERVICE_ID,
            label: t('app.common.service'),
            required: true,
            disabled: isEdit,
            colProps: {
                sm: 12,
            },
        },
        [contactProfileFields.PROPERTY_TYPE_ID]: {
            ...baseSelectInput,
            name: contactProfileFields.PROPERTY_TYPE_ID,
            label: t('app.common.property.type'),
            required: true,
            disabled: isEdit,
            colProps: {
                sm: 12,
            },
        },
        [contactProfileFields.PROPERTY_SUB_TYPE_ID]: {
            ...baseMultiSelectInput,
            name: contactProfileFields.PROPERTY_SUB_TYPE_ID,
            label: t('app.common.property.subtype'),
            tooltip: t('app.common.property.subtype.tooltip'),
            colProps: {
                sm: 12,
            },
        },
        [contactProfileFields.CONTACT_PROFILE_STATUS_ID]: {
            ...baseSelectInput,
            name: contactProfileFields.CONTACT_PROFILE_STATUS_ID,
            label: t('form.contact.profile.profileStatus'),
            colProps: {
                sm: 12,
            },
        },
        [contactProfileFields.CONTACT_FINANCING_ID]: {
            ...baseMultiSelectInput,
            name: contactProfileFields.CONTACT_FINANCING_ID,
            label: t('app.common.contact.financing'),
            colProps: {
                sm: 12,
            },
        },
        [contactProfileFields.CONTACT_PROFILE_DURATION_ID]: {
            ...baseSelectInput,
            name: contactProfileFields.CONTACT_PROFILE_DURATION_ID,
            label: t('app.common.contact.realizationTime'),
            colProps: {
                sm: 12,
            },
        },
        [contactProfileFields.CONTACT_DEFINITION_ID]: {
            ...baseSelectInput,
            name: contactProfileFields.CONTACT_DEFINITION_ID,
            label: t('app.common.contact.definition'),
            colProps: {
                sm: 12,
            },
        },
        [contactProfileFields.CONTACT_PROFILE_REMARK_ID]: {
            ...baseMultiSelectInput,
            name: contactProfileFields.CONTACT_PROFILE_REMARK_ID,
            label: t('app.common.contact.comment'),
            colProps: {
                sm: 12,
            },
        },
        [contactProfileFields.COUNTRY_ID]: {
            ...baseSelectInput,
            name: contactProfileFields.COUNTRY_ID,
            label: t('app.common.country'),
        },
        [contactProfileFields.COUNTY_ID]: {
            ...baseSelectInput,
            name: contactProfileFields.COUNTY_ID,
            label: t('app.common.county'),
        },
        [contactProfileFields.CITY_ID]: {
            ...baseMultiSelectInput,
            name: contactProfileFields.CITY_ID,
            label: t('app.common.cityMunicipality'),
        },
        [contactProfileFields.WIDER_AREA_ID]: {
            ...baseMultiSelectInput,
            name: contactProfileFields.WIDER_AREA_ID,
            label: t('app.common.widerArea'),
        },
        [contactProfileFields.QUARTER_ID]: {
            ...baseMultiSelectInput,
            name: contactProfileFields.QUARTER_ID,
            label: t('app.common.quarter'),
        },
        [contactProfileFields.ISLAND_ID]: {
            ...baseMultiSelectInput,
            name: contactProfileFields.ISLAND_ID,
            label: t('app.common.island'),
        },
        [contactProfileFields.PRICE_FROM]: {
            ...basePriceInput,
            name: contactProfileFields.PRICE_FROM,
            label: t('form.contact.profile.priceFrom', { currency: renderCurrency(appSettings?.currency) }),
            colProps: {
                sm: 12,
                xxl: 6,
            },
        },
        [contactProfileFields.PRICE_TO]: {
            ...basePriceInput,
            name: contactProfileFields.PRICE_TO,
            label: t('form.contact.profile.priceTo', { currency: renderCurrency(appSettings?.currency) }),
            colProps: {
                sm: 12,
                xxl: 6,
            },
        },
        [contactProfileFields.PRICE_M2_FROM]: {
            ...basePriceInput,
            name: contactProfileFields.PRICE_M2_FROM,
            label: t('form.contact.profile.pricePerUnitFrom', {
                currencyWithUnit: renderCurrencyWithUnit(appSettings?.currency, appSettings?.areaUnit),
            }),
            colProps: {
                sm: 12,
                xxl: 6,
            },
        },
        [contactProfileFields.PRICE_M2_TO]: {
            ...basePriceInput,
            name: contactProfileFields.PRICE_M2_TO,
            label: t('form.contact.profile.pricePerUnitTo', {
                currencyWithUnit: renderCurrencyWithUnit(appSettings?.currency, appSettings?.areaUnit),
            }),
            colProps: {
                sm: 12,
                xxl: 6,
            },
        },
        [contactProfileFields.ROOMS_FROM]: {
            ...baseTextInput,
            name: contactProfileFields.ROOMS_FROM,
            label: t('form.contact.profile.roomsFrom'),
            colProps: {
                sm: 12,
                xxl: 6,
            },
        },
        [contactProfileFields.ROOMS_TO]: {
            ...baseTextInput,
            name: contactProfileFields.ROOMS_TO,
            label: t('form.contact.profile.roomsTo'),
            colProps: {
                sm: 12,
                xxl: 6,
            },
        },
        [contactProfileFields.BEDROOMS_FROM]: {
            ...baseTextInput,
            name: contactProfileFields.BEDROOMS_FROM,
            label: t('form.contact.profile.bedroomsFrom'),
            colProps: {
                sm: 12,
                xxl: 6,
            },
        },
        [contactProfileFields.BEDROOMS_TO]: {
            ...baseTextInput,
            name: contactProfileFields.BEDROOMS_TO,
            label: t('form.contact.profile.bedroomsTo'),
            colProps: {
                sm: 12,
                xxl: 6,
            },
        },
        [contactProfileFields.AREA_FROM]: {
            ...baseTextInput,
            name: contactProfileFields.AREA_FROM,
            label: t('form.contact.profile.areaFrom', { unit: renderAreaUnit(appSettings?.areaUnit) }),
            colProps: {
                sm: 12,
                xxl: 2,
            },
        },
        [contactProfileFields.AREA_TO]: {
            ...baseTextInput,
            name: contactProfileFields.AREA_TO,
            label: t('form.contact.profile.areaTo', { unit: renderAreaUnit(appSettings?.areaUnit) }),
            colProps: {
                sm: 12,
                xxl: 2,
            },
        },
        [contactProfileFields.PROPERTY_FLOOR_POSITION_ID]: {
            ...baseMultiSelectInput,
            name: contactProfileFields.PROPERTY_FLOOR_POSITION_ID,
            label: t('app.common.floorPosition'),
        },
        [contactProfileFields.FLOOR_NO_FROM]: {
            ...baseTextInput,
            name: contactProfileFields.FLOOR_NO_FROM,
            label: t('form.contact.profile.floorFrom'),
        },
        [contactProfileFields.FLOOR_NO_TO]: {
            ...baseTextInput,
            name: contactProfileFields.FLOOR_NO_TO,
            label: t('form.contact.profile.floorTo'),
        },
        [contactProfileFields.EXCLUDE_BASEMENT]: {
            ...baseCheckboxInput,
            name: contactProfileFields.EXCLUDE_BASEMENT,
            label: t('app.common.contact.excludeGroundFloor'),
        },
        [contactProfileFields.EXCLUDE_LAST_FLOOR]: {
            ...baseCheckboxInput,
            name: contactProfileFields.EXCLUDE_LAST_FLOOR,
            label: t('app.common.contact.excludeLastFloor'),
        },
        [contactProfileFields.PROPERTY_SPACE_ID]: {
            ...baseMultiSelectInput,
            name: contactProfileFields.PROPERTY_SPACE_ID,
            label: t('app.common.property.space'),
        },
        [contactProfileFields.SPACE_COUNT]: {
            ...baseTextInput,
            name: contactProfileFields.SPACE_COUNT,
            label: t('app.common.spaceCount'),
        },
        [contactProfileFields.SPACE_AREA]: {
            ...baseTextInput,
            name: contactProfileFields.SPACE_AREA,
            label: t('app.common.spaceArea'),
        },
        [contactProfileFields.PROPERTY_DESCRIPTIONS_ID]: {
            ...baseMultiSelectInput,
            name: contactProfileFields.PROPERTY_DESCRIPTIONS_ID,
            label: t('app.common.property.description'),
        },
        [contactProfileFields.PROPERTY_EQUIPMENT_ID]: {
            ...baseMultiSelectInput,
            name: contactProfileFields.PROPERTY_EQUIPMENT_ID,
            label: t('app.common.equipment'),
        },
        [contactProfileFields.PROPERTY_UTILITY_ID]: {
            ...baseMultiSelectInput,
            name: contactProfileFields.PROPERTY_UTILITY_ID,
            label: t('app.common.utility'),
        },
        [contactProfileFields.PROPERTY_HEATING_ID]: {
            ...baseMultiSelectInput,
            name: contactProfileFields.PROPERTY_HEATING_ID,
            label: t('app.common.heating'),
        },
        [contactProfileFields.SPECIAL_REQUEST]: {
            ...baseTextAreaInput,
            name: contactProfileFields.SPECIAL_REQUEST,
            label: t('app.common.contact.specialRequests'),
            colProps: {
                sm: 12,
            },
            inputProps: {
                style: { resize: 'none', height: '170px' },
            },
        },
        [contactProfileFields.MAX_CONTSRUCTION_AREA]: {
            ...baseTextInput,
            name: contactProfileFields.MAX_CONTSRUCTION_AREA,
            label: t('form.contact.profile.maxConstructionArea', { unit: renderAreaUnit(appSettings?.areaUnit) }),
        },
        [contactProfileFields.PROPERTY_FLOOR_ID]: {
            ...baseSelectInput,
            name: contactProfileFields.PROPERTY_FLOOR_ID,
            label: t('app.common.numberOfFloors'),
        },
    }
}

export const contactNoteFieldTypes = () => {
    const { t } = i18next

    return {
        [contactNoteFields.NOTE_TITLE]: {
            ...baseTextInput,
            name: contactNoteFields.NOTE_TITLE,
            label: t('app.common.title'),
            colProps: {
                sm: 12,
            },
        },
        [contactNoteFields.NOTE_DATE]: {
            ...baseDateInput,
            name: contactNoteFields.NOTE_DATE,
            label: t('app.common.date'),
            colProps: {
                sm: 12,
            },
        },
        [contactNoteFields.NOTE]: {
            ...baseTextAreaInput,
            name: contactNoteFields.NOTE,
            label: t('form.notes.note'),
            colProps: {
                sm: 12,
            },
            inputProps: {
                style: { resize: 'none', height: '127px' },
            },
        },
        [contactNoteFields.CONTACT_NOTE_AGENT_ID]: {
            ...baseSelectInput,
            name: contactNoteFields.CONTACT_NOTE_AGENT_ID,
            label: t('app.common.agent'),
            colProps: {
                sm: 12,
            },
        },
    }
}

export const contactDetailSearchFieldTypes = () => {
    const { t } = i18next

    return {
        [contactDetailSearchFields.NAME]: {
            ...baseTextInput,
            name: contactDetailSearchFields.NAME,
            label: t('app.common.contact.nameAndSurname'),
            colProps: {
                sm: 12,
            },
        },
        [contactDetailSearchFields.CONTACT_PHONE]: {
            ...baseTextInput,
            name: contactDetailSearchFields.CONTACT_PHONE,
            label: t('app.common.phoneNumber'),
            colProps: {
                sm: 12,
            },
        },
        [contactDetailSearchFields.CONTACT_EMAIL]: {
            ...baseTextInput,
            name: contactDetailSearchFields.CONTACT_EMAIL,
            label: t('app.common.email.label'),
            colProps: {
                sm: 12,
            },
        },
        [contactDetailSearchFields.AGENT]: {
            ...baseSelectInput,
            name: contactDetailSearchFields.AGENT,
            label: t('app.common.agent'),
            colProps: {
                sm: 12,
            },
        },
        [contactDetailSearchFields.INITIAL_CONTACT_DATE_FROM]: {
            ...baseDateInput,
            name: contactDetailSearchFields.INITIAL_CONTACT_DATE_FROM,
            placeholder: t('app.common.from'),
            colProps: {
                sm: 6,
                className: 'pe-0',
            },
        },
        [contactDetailSearchFields.INITIAL_CONTACT_DATE_TO]: {
            ...baseDateInput,
            name: contactDetailSearchFields.INITIAL_CONTACT_DATE_TO,
            placeholder: t('app.common.to'),
            colProps: {
                sm: 6,
            },
        },
        [contactDetailSearchFields.LAST_ACTIVITY_DATE_FROM]: {
            ...baseDateInput,
            name: contactDetailSearchFields.LAST_ACTIVITY_DATE_FROM,
            placeholder: t('app.common.from'),
            colProps: {
                sm: 6,
                className: 'pe-0',
            },
        },
        [contactDetailSearchFields.LAST_ACTIVITY_DATE_TO]: {
            ...baseDateInput,
            name: contactDetailSearchFields.LAST_ACTIVITY_DATE_TO,
            placeholder: t('app.common.to'),
            colProps: {
                sm: 6,
            },
        },
        [contactDetailSearchFields.CONTACT_COUNTRY]: {
            ...baseSelectInput,
            name: contactDetailSearchFields.CONTACT_COUNTRY,
            label: t('app.common.country'),
            colProps: {
                sm: 12,
            },
        },
        [contactDetailSearchFields.CITY]: {
            ...baseTextInput,
            name: contactDetailSearchFields.CITY,
            label: t('app.common.cityMunicipality'),
            colProps: {
                sm: 12,
            },
        },
        [contactDetailSearchFields.CONTACT_STATUS]: {
            ...baseMultiSelectInput,
            name: contactDetailSearchFields.CONTACT_STATUS,
            label: t('app.common.status'),
            colProps: {
                sm: 12,
            },
        },
        [contactDetailSearchFields.CONTACT_PROFILE_SERVICE]: {
            ...baseSelectInput,
            name: contactDetailSearchFields.CONTACT_PROFILE_SERVICE,
            label: t('form.contact.contactDetailSearch.profileType'),
            colProps: {
                sm: 12,
            },
        },
        [contactDetailSearchFields.CONTACT_PROFILE_STATUS]: {
            ...baseSelectInput,
            name: contactDetailSearchFields.CONTACT_PROFILE_STATUS,
            label: t('app.common.contact.profileStatus'),
            colProps: {
                sm: 12,
            },
        },
        [contactDetailSearchFields.CONTACT_QUERY_CATEGORY]: {
            ...baseSelectInput,
            name: contactDetailSearchFields.CONTACT_QUERY_CATEGORY,
            label: t('app.common.contact.queryCategory'),
            colProps: {
                sm: 12,
            },
        },
        [contactDetailSearchFields.CONTACT_PRIORITY]: {
            ...baseSelectInput,
            name: contactDetailSearchFields.CONTACT_PRIORITY,
            label: t('app.common.contact.priority'),
            colProps: {
                sm: 12,
            },
        },
        [contactDetailSearchFields.DATA_SOURCE]: {
            ...baseSelectInput,
            name: contactDetailSearchFields.DATA_SOURCE,
            label: t('app.common.contact.dataSource'),
            colProps: {
                sm: 12,
            },
        },
    }
}

export const contactProfileDetailSearchFieldTypes = () => {
    const { t } = i18next

    return {
        [responseFields.PROPERTY_TYPE]: {
            ...baseMultiSelectInput,
            name: responseFields.PROPERTY_TYPE,
            label: t('app.common.property.type'),
            colProps: {
                sm: 12,
            },
        },
        [responseFields.PROPERTY_SUB_TYPE]: {
            ...baseMultiSelectInput,
            name: responseFields.PROPERTY_SUB_TYPE,
            label: t('app.common.property.subtype'),
            colProps: {
                sm: 12,
            },
        },
        [responseFields.PRICE_FROM]: {
            ...baseTextInput,
            name: responseFields.PRICE_FROM,
            placeholder: t('app.common.from'),
            colProps: {
                sm: 6,
                className: 'pe-0',
            },
        },
        [responseFields.PRICE_TO]: {
            ...baseTextInput,
            name: responseFields.PRICE_TO,
            placeholder: t('app.common.to'),
            colProps: {
                sm: 6,
            },
        },
        [responseFields.ROOMS_FROM]: {
            ...baseTextInput,
            name: responseFields.ROOMS_FROM,
            placeholder: t('app.common.from'),
            colProps: {
                sm: 6,
                className: 'pe-0',
            },
        },
        [responseFields.ROOMS_TO]: {
            ...baseTextInput,
            name: responseFields.ROOMS_TO,
            placeholder: t('app.common.to'),
            colProps: {
                sm: 6,
            },
        },
        [responseFields.BEDROOMS_FROM]: {
            ...baseTextInput,
            name: responseFields.BEDROOMS_FROM,
            placeholder: t('app.common.from'),
            colProps: {
                sm: 6,
                className: 'pe-0',
            },
        },
        [responseFields.BEDROOMS_TO]: {
            ...baseTextInput,
            name: responseFields.BEDROOMS_TO,
            placeholder: t('app.common.to'),
            colProps: {
                sm: 6,
            },
        },
        [responseFields.CONTACT_PROFILE_DURATION]: {
            ...baseSelectInput,
            name: responseFields.CONTACT_PROFILE_DURATION,
            label: t('app.common.contact.realizationTime'),
            colProps: {
                sm: 12,
            },
        },
        [responseFields.AREA_FROM]: {
            ...baseTextInput,
            name: responseFields.AREA_FROM,
            placeholder: t('app.common.from'),
            colProps: {
                sm: 6,
                className: 'pe-0',
            },
        },
        [responseFields.AREA_TO]: {
            ...baseTextInput,
            name: responseFields.AREA_TO,
            placeholder: t('app.common.to'),
            colProps: {
                sm: 6,
            },
        },
        [responseFields.FLOOR_FROM]: {
            ...baseTextInput,
            name: responseFields.FLOOR_FROM,
            placeholder: t('app.common.from'),
            colProps: {
                sm: 6,
                className: 'pe-0',
            },
        },
        [responseFields.FLOOR_TO]: {
            ...baseTextInput,
            name: responseFields.FLOOR_TO,
            placeholder: t('app.common.to'),
            colProps: {
                sm: 6,
            },
        },
        [responseFields.COUNTRY]: {
            ...baseSelectInput,
            name: responseFields.COUNTRY,
            label: t('app.common.country'),
            colProps: {
                sm: 12,
            },
        },
        [responseFields.CITY]: {
            ...baseMultiSelectInput,
            name: responseFields.CITY,
            label: t('app.common.cityMunicipality'),
            disabled: true,
            colProps: {
                sm: 12,
            },
        },
        [responseFields.COUNTY]: {
            ...baseMultiSelectInput,
            name: responseFields.COUNTY,
            label: t('app.common.county'),
            disabled: true,
            colProps: {
                sm: 12,
            },
        },
        [responseFields.WIDER_AREA]: {
            ...baseMultiSelectInput,
            name: responseFields.WIDER_AREA,
            label: t('app.common.widerArea'),
            disabled: true,
            colProps: {
                sm: 12,
            },
        },
        [responseFields.QUARTER]: {
            ...baseMultiSelectInput,
            name: responseFields.QUARTER,
            label: t('app.common.quarter'),
            disabled: true,
            colProps: {
                sm: 12,
            },
        },
        [responseFields.PROPERTY_FLOOR]: {
            ...baseMultiSelectInput,
            name: responseFields.PROPERTY_FLOOR,
            label: t('app.common.numberOfFloors'),
            colProps: {
                sm: 12,
            },
        },
    }
}

export const contactDocumentFieldTypes = () => {
    const { t } = i18next

    return {
        [contactDocumentFields.DOCUMENT_PROJECT_ID]: {
            ...baseTextInput,
            disabled: true,
            name: contactDocumentFields.DOCUMENT_PROJECT_ID,
            label: t('app.common.projectId'),
            colProps: {
                sm: 6,
            },
        },
        [contactDocumentFields.DOCUMENT_PROPRETY_ID]: {
            ...baseTextInput,
            disabled: true,
            name: contactDocumentFields.DOCUMENT_PROPRETY_ID,
            label: t('app.common.propertyId'),
            colProps: {
                sm: 6,
            },
        },
        [contactDocumentFields.DOCUMENT_TYPE]: {
            ...baseSelectInput,
            name: contactDocumentFields.DOCUMENT_TYPE,
            label: t('app.common.documentType'),
            colProps: {
                sm: 12,
            },
        },
        [contactDocumentFields.DOCUMENT_CONTACT_ID]: {
            ...baseAsyncSelectInput,
            name: contactDocumentFields.DOCUMENT_CONTACT_ID,
            label: t('app.common.contactCompany'),
            criteria: ['name'],
            action: fetchContactsAsyncInput,
            noLimit: true,
            colProps: {
                sm: 12,
            },
        },
        [contactDocumentFields.DOCUMENT_CREATION_DATE]: {
            ...baseDateInput,
            name: contactDocumentFields.DOCUMENT_CREATION_DATE,
            label: t('app.common.creationDate'),
            colProps: {
                sm: 6,
            },
        },
        [contactDocumentFields.DOCUMENT_VALIDITY_DATE]: {
            ...baseDateInput,
            name: contactDocumentFields.DOCUMENT_VALIDITY_DATE,
            label: t('app.common.expirationDate'),
            colProps: {
                sm: 6,
            },
        },
        [contactDocumentFields.FILE_SYNC]: {
            ...baseCheckboxInput,
            name: contactDocumentFields.FILE_SYNC,
            label: t('app.common.synchronize'),
            colProps: {
                sm: 12,
            },
        },
    }
}
