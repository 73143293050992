import { useState } from 'react'
import { GoogleMap, useJsApiLoader, Marker, InfoWindow, MarkerClusterer } from '@react-google-maps/api'
import { iconsSrc } from './iconsSrc'
import InfoWindowContent from './InfoWindowContent/InfoWindowContent'
import Loader from '../../../../Components/Common/Loader'
import style from './MapProperty.module.css'

const mapPosition = {
    lat: 44,
    lng: 16,
}

const MapProperty = (props) => {
    const { tableActions, properties, isDataLoading } = props

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-properties',
        googleMapsApiKey: process.env.REACT_APP_GOOGLEMAPID,
    })

    const [showInfoWindow, setShowInfoWindow] = useState()
    const [map, setMap] = useState()

    const handleInfoWindowShow = (propertyId) => {
        const propertyById = properties.find((property) => property?.id === propertyId)
        setShowInfoWindow(propertyById)
        map.panTo({
            lat: parseFloat(propertyById?.mapLatitude),
            lng: parseFloat(propertyById?.mapLongitude),
        })
    }

    const handleInfoWindowHide = () => {
        setShowInfoWindow(null)
    }

    return (
        <>
            {tableActions()}
            {isLoaded && !isDataLoading ? (
                <GoogleMap
                    onLoad={(map) => setMap(map)}
                    options={{ gestureHandling: 'cooperative' }}
                    mapContainerClassName={style.mapContainer}
                    panto
                    center={mapPosition}
                    zoom={7}
                    onZoomChanged={handleInfoWindowHide}
                >
                    <MarkerClusterer>
                        {(clusterer) =>
                            properties.map((item) => {
                                const icon = iconsSrc.find((icon) => icon?.id === item.propertyType?.id)
                                return (
                                    <Marker
                                        onClick={() => handleInfoWindowShow(item?.id)}
                                        key={item.id}
                                        position={{
                                            lat: parseFloat(item?.mapLatitude),
                                            lng: parseFloat(item?.mapLongitude),
                                        }}
                                        icon={{
                                            url: icon.src,
                                        }}
                                        clusterer={clusterer}
                                    >
                                        {showInfoWindow?.id === item?.id ? (
                                            <InfoWindow
                                                position={{
                                                    lat: parseFloat(showInfoWindow?.mapLatitude),
                                                    lng: parseFloat(showInfoWindow?.mapLongitude),
                                                }}
                                                onCloseClick={handleInfoWindowHide}
                                            >
                                                <InfoWindowContent {...showInfoWindow} />
                                            </InfoWindow>
                                        ) : (
                                            <></>
                                        )}
                                    </Marker>
                                )
                            })
                        }
                    </MarkerClusterer>
                </GoogleMap>
            ) : (
                <div className="text-center d-flex justify-content-center" style={{ position: 'absolute', inset: '100px 0' }}>
                    <Loader />
                </div>
            )}
        </>
    )
}

export default MapProperty
