import ImageEditor from '@toast-ui/react-image-editor'
import { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import 'tui-color-picker/dist/tui-color-picker.css'
import 'tui-image-editor/dist/tui-image-editor.css'
import { SUBMIT_RESPONSE_STATUS } from '../../common/constants'
import { proxyGroundPlanEdit, proxyGroundPlanUpdateList, updateGroundPlan } from '../../store/groundplans'
import { selectCount, setCount } from '../../store/imageEditor'
import { proxyPhotoEdit, proxyPhotoUpdateList, selectPhotos, updatePhoto } from '../../store/photos'
import { crop1_1button, cropNewButton, handleInputTooltip, undoButton } from './buttonUtils'
import './styles.css'
import { whiteTheme } from './theme'
import i18n from '../../i18n'
import {
    actionButtonsTranslation,
    cropTranslations,
    drawTranslation,
    filterTranslation,
    iconsTranslation,
    resizeTranslations,
    rotateDrawRangeTranslation,
    shapeTranslation,
    textTranslation,
    toolbarTranslations,
    useActiveButton,
} from './translationUtils'
import { useParams } from 'react-router-dom'

const ToastImageEditor = ({ image, module }) => {
    const [editorInstance, setEditorInstance] = useState(null)
    const editorRef = useRef()
    const { id } = useParams()

    const dispatch = useDispatch()

    const { count, photos } = useSelector((state) => ({
        count: selectCount(state),
        photos: selectPhotos(state),
    }))

    const isPhoto = photos.some((photo) => photo.id === image?.id)

    // set editor instance
    useEffect(() => {
        if (editorRef.current) {
            const editorInstance = editorRef.current.getInstance()
            setEditorInstance(editorInstance)
        }
    }, [editorRef, setEditorInstance])

    const handleUpdate = (dispatch, updateAction, proxyUpdateListAction, proxyEditAction, image, base64) => {
        dispatch(
            updateAction(
                { entity: { id }, id: image.id, file: base64, module },
                {
                    callback: (responseStatus) => {
                        const progressToast = toast.loading(i18n.t('toast.loading.images'))

                        if (responseStatus === SUBMIT_RESPONSE_STATUS.SUCCESS) {
                            dispatch(proxyUpdateListAction())
                            dispatch(proxyEditAction(image.id))

                            toast.update(progressToast, {
                                render: i18n.t('toast.loading.images.success'),
                                type: 'success',
                                isLoading: false,
                                autoClose: 3000,
                            })
                        } else {
                            toast.update(progressToast, {
                                render: i18n.t('toast.loading.images.error'),
                                type: 'error',
                                isLoading: false,
                                autoClose: 3000,
                            })
                        }

                        dispatch(setCount(0))
                    },
                }
            )
        )
    }

    // save image changes
    useEffect(() => {
        const base64 = editorInstance?.toDataURL()

        if (!base64) return

        if (count === 1) {
            isPhoto
                ? handleUpdate(dispatch, updatePhoto, proxyPhotoUpdateList, proxyPhotoEdit, image, base64)
                : handleUpdate(dispatch, updateGroundPlan, proxyGroundPlanUpdateList, proxyGroundPlanEdit, image, base64)
        }
    }, [count, editorInstance, dispatch, image, isPhoto])

    // render custom buttons and translations
    useEffect(() => {
        const editorInstance = editorRef.current?.getInstance()

        crop1_1button()
        cropNewButton(editorInstance, '.preset-4-3', '3:4', 3 / 4)
        cropNewButton(editorInstance, '.preset-16-9', '9:16', 9 / 16)
        undoButton(editorInstance)
        handleInputTooltip()

        toolbarTranslations()
        cropTranslations()
        resizeTranslations()
        drawTranslation()
        shapeTranslation()
        iconsTranslation()
        textTranslation()
        filterTranslation()
    }, [])

    // trigger translations of action buttons and range inputs (rotate & draw)
    useActiveButton('.tie-btn-crop', '.tie-btn-resize', actionButtonsTranslation)
    useActiveButton('.tie-btn-rotate', '.tie-btn-draw', rotateDrawRangeTranslation)

    // inserts GridDimensions component
    const portalContainerRef = useRef(document.createElement('div'))
    const subMenu = document.querySelector('.tui-image-editor-submenu-item')

    useEffect(() => {
        if (subMenu) {
            subMenu.insertAdjacentElement('beforebegin', portalContainerRef.current)
        }
    }, [subMenu])

    // see all props for editor
    // https://www.npmjs.com/package/@toast-ui/react-image-editor
    return (
        <>
            <ImageEditor
                ref={editorRef}
                includeUI={{
                    loadImage: {
                        path: image.url,
                        name: 'image',
                    },
                    menu: ['crop', 'resize', 'rotate', 'draw', 'shape', 'icon', 'text', 'filter'],
                    initMenu: 'crop',
                    uiSize: {
                        height: '710px',
                    },
                    theme: whiteTheme,
                }}
                usageStatistics={false}
                selectionStyle={{
                    cornerStyle: 'circle',
                    cornerSize: 10,
                    cornerColor: 'white',
                    cornerStrokeColor: 'white',
                    transparentCorners: false,
                    lineWidth: 1,
                    borderColor: 'white',
                }}
                cssMaxHeight={450} // controls maximum height of the image canvas
            />
            {ReactDOM.createPortal(<GridDimensionsDisplay editorInstance={editorInstance} />, portalContainerRef.current)}
        </>
    )
}

const GridDimensionsDisplay = ({ editorInstance }) => {
    const [gridDimensions, setGridDimensions] = useState(null)
    const buttons = ['.preset-none', '.tie-btn-crop', '.tie-btn-resize']

    useEffect(() => {
        if (!editorInstance) return

        const handleGridDimensions = (objectProps) => {
            if (objectProps.type === 'cropzone') {
                const gridWidth = Math.round(objectProps?.width)
                const gridHeight = Math.round(objectProps?.height)

                setGridDimensions(`${gridWidth} x ${gridHeight}`)
            }
        }

        editorInstance.on('objectActivated', handleGridDimensions)
        editorInstance.on('objectScaled', handleGridDimensions)
    }, [editorInstance, setGridDimensions])

    const resetGrid = (selector) => {
        const button = document.querySelector(selector)

        if (button) {
            button.addEventListener('click', () => setGridDimensions(null))
        }
    }

    buttons.forEach((button) => resetGrid(button))

    return <div className="grid-dimensions-display">{gridDimensions}</div>
}

export default ToastImageEditor
