import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import TableCheckbox from '../../../Table/TableCheckbox'

const ContactColumns = () => {
    const { t } = useTranslation()

    const columns = [
        {
            Header: ({ getToggleAllRowsSelectedProps }) => <TableCheckbox {...getToggleAllRowsSelectedProps()} />,
            Cell: ({ row }) => {
                return <TableCheckbox {...row.getToggleRowSelectedProps()} />
            },
            id: '#',
            minWidth: 45,
        },
        {
            Header: t('app.common.id'),
            id: 'contact',
            Cell: (item) => {
                return item.row.original?.contact?.id
            },
            filterable: true,
            minWidth: 50,
        },
        {
            Header: t('app.common.contact.nameAndSurname'),
            id: 'contactPersonName',
            Cell: (item) => {
                const contactPerson = item.row.original?.contact?.contactPerson
                return `${contactPerson?.firstName ?? ''} ${contactPerson?.lastName ?? ''}`
            },
            filterable: true,
            minWidth: 235,
        },
        {
            Header: t('app.common.company.name'),
            id: 'contactCompanyName',
            Cell: (item) => {
                return item.row.original?.contact?.contactCompany?.name
            },
            filterable: true,
            minWidth: 180,
        },
        {
            Header: t('app.common.contactPhoneNumber1'),
            id: 'contactPhone',
            Cell: (item) => {
                const firstPhone = item.row.original?.contact?.contactPhone
                return firstPhone?.length > 0 ? firstPhone[0]?.phoneNumber : ''
            },
            filterable: true,
            minWidth: 135,
        },
        {
            Header: t('app.common.email.label'),
            id: 'contactEmail',
            Cell: (item) => {
                const email = item.row.original?.contact?.contactEmail
                return email?.length > 0 ? email[0]?.email : ''
            },
            filterable: true,
            minWidth: 280,
        },
        {
            Header: t('app.common.agent'),
            id: 'agent',
            Cell: (item) => {
                const agent = item.row.original?.contact?.agent
                return `${agent?.firstName} ${agent?.lastName}`
            },
            filterable: true,
            minWidth: 200,
        },
        {
            id: 'actions',
            thDivClass: 'text-center',
            Cell: (cellProps) => {
                return (
                    <ul className="list-inline hstack gap-2 mb-0 justify-content-center">
                        <li className="list-inline-item edit">
                            <Link
                                title={t('button.edit')}
                                to={`/contacts/${cellProps.row.original?.contact?.id}`}
                                className="text-info d-inline-block edit-item-btn"
                            >
                                <i className="mdi mdi-eye-outline fs-16"></i>
                            </Link>
                        </li>
                    </ul>
                )
            },
            minWidth: 40,
        },
    ]

    return columns
}

export default ContactColumns
