import classNames from 'classnames'
import { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardBody, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap'
import SimpleBar from 'simplebar-react'
import DeleteModal from '../../Components/Common/DeleteModal'
import TableContainer from '../../Components/Table/TableContainer'
import { resetTableCheckbox } from '../../store/table'
import { selectCheckedTableRows } from '../../store/table/selectors'
import FormMainContainer from '../Common/FormMainContainer'
import FormSideContainer from '../Common/FormSideContainer'
import DocumentForm from '../DocumentForm'
import useDocumentsHeaders from '../DocumentViewTab/hooks/useDocumentsHeaders'
import { useFormatTableData } from '../Table/utils'
import DocumentFormSidebar from './DocumentFormSidebar'
import documents from '../../api/documents/hooks'
import { useParams } from 'react-router-dom'
import { removeDuplicates } from '../../api/helpers'

const DocumentFormTab = ({ documentModule, tabs = [], activeTab, handleTabChange, contactType }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { id } = useParams()

    const parentId = id ? id.toString() : 'null'
    const parentModul = documentModule ? documentModule.toString() : 'null'

    const refreshToken = localStorage.getItem('refresh_token')

    const [documentModuleId, setDocumentModuleId] = useState()
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(20)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteModalMulti, setDeleteModalMulti] = useState(false)
    const [rowId, setRowId] = useState(null)
    const [sidebarData, setSidebarData] = useState('')
    const [sidebarEditMode, setSidebarEditMode] = useState(false)
    const totalPages = useRef()
    const invalidateData = useRef()

    const {
        isLoading: fetchingDocuments,
        data: documentsData,
        isFetched,
    } = documents.GetDocuments.useQuery(parentId, page, limit, parentModul, 'document', refreshToken)

    const { mutate: deleteMutate } = documents.DeleteDocument.useMutation(invalidateData, limit, 'document')
    const { mutate: deleteMultipleMutate } = documents.DeleteMultipleDocuments.useMutation(invalidateData, limit, 'document')
    const { mutate: syncMutate } = documents.SyncDocument.useMutation(invalidateData, limit, 'document')

    if (isFetched) totalPages.current = documentsData?.pagination?.totalPages

    const { selectedTableRows } = useSelector((state) => ({
        selectedTableRows: selectCheckedTableRows(state),
    }))

    useEffect(() => {
        switch (documentModule) {
            case 'property':
                setDocumentModuleId({ documentPropertyId: id, documentModule: documentModule })
                break
            case 'project':
                setDocumentModuleId({ documentProjectId: id, documentModule: documentModule })
                break
            case 'contact':
                setDocumentModuleId({ documentContactId: id, documentModule: documentModule })
                break
            default:
                break
        }
    }, [documentModule])

    function sidebarClose(value) {
        const element = document.getElementsByTagName('body')[0]
        element.classList.remove(value)
        setSidebarEditMode(false)
        setSidebarData('')
    }

    useEffect(() => {
        return () => {
            sidebarClose('file-detail-show')
        }
    }, [])

    const handleDataFetch = (page, limit) => {
        setPage(page)
        setLimit(limit)
    }

    const onClickDelete = (id) => {
        setRowId(id)
        setDeleteModal(true)
    }

    const handleDeleteDocument = () => {
        deleteMutate(rowId)
        dispatch(resetTableCheckbox(true))
        setDeleteModal(false)
    }

    const deleteMultipleDocuments = () => {
        const selectedRowsIds = selectedTableRows.map((row) => row.id)
        deleteMultipleMutate(selectedRowsIds)
        dispatch(resetTableCheckbox(true))
        setDeleteModalMulti(false)
    }

    const tableData = useFormatTableData(documentsData?.items, 'documents')
    const columns = useDocumentsHeaders(documentModule, syncMutate, setSidebarData, setSidebarEditMode, onClickDelete, 'documents', invalidateData)

    const tableActions = () => (
        <div className="d-flex flex-grow-1">
            <UncontrolledDropdown className="ms-2">
                <DropdownToggle
                    title={t('table.showMore')}
                    href="#"
                    className="btn btn-primary dropdown"
                    tag="button"
                    onClick={(e) => e.preventDefault()}
                >
                    <i className="mdi mdi-dots-horizontal align-middle"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem
                        onClick={() => {
                            setDeleteModalMulti(true)
                            const selectedAllItems = selectedTableRows.map((item) => {
                                return { id: item.parent, module: item.module }
                            })
                            invalidateData.current = removeDuplicates(selectedAllItems, 'id')
                        }}
                        disabled={selectedTableRows.length > 0 ? false : true}
                    >
                        <i className="mdi mdi-trash-can-outline align-bottom me-2 text-muted"></i> {t('table.action.deleteSelected')}
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    )

    return (
        <>
            <Row>
                <FormSideContainer>
                    <Card>
                        <CardBody>
                            <DocumentForm
                                hasUpload={true}
                                isEdit={false}
                                documentData={documentModuleId}
                                contactType={contactType}
                                documentModule={documentModule}
                                parentId={parentId}
                                activeTab={activeTab}
                                totalPage={documentsData?.pagination?.totalPages}
                                limit={limit}
                            />
                        </CardBody>
                    </Card>
                </FormSideContainer>

                <FormMainContainer tabs={tabs} activeTab={activeTab} handleTabChange={handleTabChange}>
                    <DeleteModal show={deleteModal} onDeleteClick={() => handleDeleteDocument()} onCloseClick={() => setDeleteModal(false)} />
                    <DeleteModal
                        show={deleteModalMulti}
                        onDeleteClick={() => {
                            deleteMultipleDocuments()
                        }}
                        onCloseClick={() => setDeleteModalMulti(false)}
                    />
                    <div className="chat-wrapper d-lg-flex gap-1 p-0">
                        <div className={classNames('file-manager-content w-100 px-0 py-0', { 'pe-2': sidebarData })}>
                            <SimpleBar className="overflow-hidden">
                                <TableContainer
                                    columns={columns}
                                    tableActions={tableActions}
                                    data={tableData || []}
                                    isGlobalFilter={false}
                                    isAddUserList={false}
                                    isLoading={fetchingDocuments}
                                    customPageSize={20}
                                    tableClass="dmn-table--property-documents"
                                    theadClass={'dmn-table-header'}
                                    totalCount={documentsData?.pagination?.totalItems}
                                    pageCount={totalPages.current}
                                    responsive={false}
                                    handleDataFetch={handleDataFetch}
                                />
                            </SimpleBar>
                        </div>
                        <DocumentFormSidebar
                            sidebarData={sidebarData}
                            sidebarClose={sidebarClose}
                            onClickDelete={onClickDelete}
                            isEdit={sidebarEditMode}
                            contactType={contactType}
                            documentModule={documentModule}
                            parentId={parentId}
                            limit={limit}
                            invalidateData={invalidateData}
                        />
                    </div>
                </FormMainContainer>
            </Row>
        </>
    )
}

export default DocumentFormTab
