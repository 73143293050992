import values from 'lodash/values'
import { stateKey } from './actions'

export const selectAgents = (state) => values(state[stateKey].items)

export const selectAgentsFormOption = (state) => {
    const data = values(state[stateKey].items)

    return data.length
        ? data.map((item) => ({
              value: item.id?.toString(),
              label: item.firstName + ' ' + item.lastName,
              agentId: item.id,
              agencyOfficeId: item.agencyOffice?.id,
          }))
        : []
}

export const selectAgentById = (state, agentId) => {
    const agentsArray = Object.values(state.agents.items)
    return agentsArray.find((agent) => agent.id === agentId)
}

export const selectAgentIdFromItem = (state, module, id) => {
    const data = getAgentData(state, module, id)

    if (!data) return null

    const { isContactsOrCompanies, agent } = data

    // Contact & Companies agent data is object, Projects & Properties it is array
    return isContactsOrCompanies ? agent?.id : agent?.map((item) => ({ id: item.id }))
}

export const selectAgentOfficeIdFromItem = (state, module, id) => {
    const data = getAgentData(state, module, id)
    
    if (!data) return null

    const { isContactsOrCompanies, agent } = data

    return isContactsOrCompanies ? agent?.agencyOffice?.id : agent?.map((item) => ({ id: item.agencyOffice?.id }))
}

export const selectAdminUsers = (state) => values(state[stateKey].usersArray)

export const selectUser = (state) => state[stateKey].user
export const selectUserFetching = (state) => state[stateKey].fetchingUser

// helper function for selectAgentIdFromItem & selectAgentOfficeIdFromItem
const getAgentData = (state, module, id) => {
    if (!module || !id) return null

    const isContactsOrCompanies = ['contacts', 'companies'].includes(module)
    const agent = state[module]?.item?.agent

    if (!agent) return null

    return { isContactsOrCompanies, agent }
}
