import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query'
import { createUpdateProperty, deleteMultipleProperties, deletePropertyById, getOpportunityById, getProperties, getPropertyById } from './functions'
import { AxiosError } from 'axios'
import { OpportunityActivity, Property, PropertyList } from './types'
import { invalidateQueryInTable } from '../helpers'
import { PageListParams } from '../globalTypes'
import { toast } from 'react-toastify'
import i18n from '../../i18n'
import { queryClient } from '../..'
import { useHistory } from 'react-router-dom'

const properties = {
    GetProperty: {
        useQuery: (id: number, refreshToken: string, options?: UseQueryOptions<Property, AxiosError>) =>
            useQuery({
                queryKey: ['property', id, refreshToken],
                queryFn: () => getPropertyById(id),
                staleTime: 1 * 60 * 1000,
                ...options,
            }),
    },
    GetProperties: {
        useQuery: (payload: PageListParams, refreshToken: string, options?: UseQueryOptions<PropertyList, AxiosError>) =>
            useQuery({
                queryKey: [payload.page, payload.limit, payload.order, payload.criteria, 'propertiesList', refreshToken],
                queryFn: () => getProperties(payload),
                staleTime: 1 * 60 * 1000,
                ...options,
            }),
    },
    GetOpportunity: {
        useQuery: (
            id: number,
            page: number,
            limit: number,
            order: { key: string; value: string },
            url: string,
            refreshToken: string,
            options?: UseQueryOptions<OpportunityActivity, AxiosError>
        ) =>
            useQuery({
                queryKey: [page, limit, id, url, order, refreshToken],
                queryFn: () => getOpportunityById(id, page, limit, order, url),
                staleTime: 1 * 60 * 1000,
                ...options,
            }),
    },
    CreateUpdateProperty: {
        useMutation: (propertyId: number, isEdit?: boolean, options?: UseMutationOptions<Property, AxiosError, Property>) => {
            const toastId = isEdit ? propertyId : 'new'
            const history = useHistory()

            return useMutation({
                ...options,
                onMutate: () => {
                    toast.loading(i18n.t('toast.loading') as string, { toastId: toastId })
                },
                mutationFn: (data) => createUpdateProperty(data, isEdit),
                onSuccess: (data) => {
                    isEdit && queryClient.invalidateQueries({ queryKey: ['property', propertyId.toString()] })
                    invalidateQueryInTable('propertyList')
                    toast.update(toastId, {
                        render: i18n.t('toast.update.success') as string,
                        type: 'success',
                        autoClose: 3000,
                        isLoading: false,
                    })

                    !isEdit && history.push(`/properties/${data.id}/edit`)
                },
                onError: (error) => {
                    toast.update(toastId, {
                        render: i18n.t('toast.update.error') as string,
                        type: 'error',
                        autoClose: 3000,
                        isLoading: false,
                    })
                    console.error(error)
                },
            })
        },
    },
    DeleteProperty: {
        useMutation: (options?: UseMutationOptions<Property, AxiosError, number>) => {
            const history = useHistory()
            return useMutation({
                ...options,
                mutationFn: (id) => {
                    toast.loading(i18n.t('toast.loading.delete') as string, { toastId: id.toString() })
                    return deletePropertyById(id)
                },
                onSuccess: (data) => {
                    invalidateQueryInTable('propertyList')
                    toast.update(data.id.toString(), {
                        render: i18n.t('toast.update.delete.success') as string,
                        type: 'success',
                        autoClose: 3000,
                        isLoading: false,
                    })
                    history.push(`/properties`)
                },
                onError: (error, id) => {
                    toast.update(id.toString(), {
                        render: i18n.t('toast.update.delete.error') as string,
                        type: 'error',
                        autoClose: 3000,
                        isLoading: false,
                    })
                    console.error(error)
                },
            })
        },
    },
    DeleteMultipleProperty: {
        useMutation: (options?: UseMutationOptions<{ data: unknown; status?: number }[], AxiosError, number[]>) =>
            useMutation({
                ...options,
                mutationFn: (idsArray) => {
                    const requestsToBeDeletedCount = idsArray.length
                    toast.loading(i18n.t('toast.loading.delete.number', { requestsToBeDeletedCount }) as string, { toastId: idsArray[0].toString() })
                    return deleteMultipleProperties(idsArray)
                },
                onSuccess: (data, idsArray) => {
                    const requestsToBeDeletedCount = data.length
                    const failedCount = data.filter((item) => item.status !== 200).length
                    const successCount = data.filter((item) => item.status === 200).length

                    if (successCount > 0) {
                        toast.update(idsArray[0].toString(), {
                            render: i18n.t('toast.loading.delete.number.success', { successCount, requestsToBeDeletedCount }) as string,
                            type: 'success',
                            isLoading: false,
                            autoClose: 3000,
                        })
                        invalidateQueryInTable('propertyList')
                    }
                    if (failedCount > 0) {
                        toast.update(idsArray[0].toString(), {
                            render: i18n.t('toast.loading.delete.number.error', { failedCount, requestsToBeDeletedCount }) as string,
                            type: 'error',
                            isLoading: false,
                            autoClose: 3000,
                        })
                    }
                },
            }),
    },
}

export default properties
