import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-toastify/dist/ReactToastify.css'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import PageTitle from '../../Components/Common/PageTitle'
import DocumentFormAdd from '../../Components/DocumentFormAdd/DocumentFormAdd'
import DocumentViewTab from '../../Components/DocumentViewTab/Index'

const DocumentList = () => {
    const { t } = useTranslation()

    const [openDocumentFormAdd, setOpenDocumentFormAdd] = useState(false)

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <PageTitle title={t('app.common.documents')} />
                    <Row>
                        {openDocumentFormAdd && (
                            <Col sm={12} xxl={3}>
                                <DocumentFormAdd setOpenDocumentFormAdd={setOpenDocumentFormAdd} />
                            </Col>
                        )}
                        <Col sm={12} xxl={openDocumentFormAdd ? 9 : 12}>
                            <Card id="RealEstatesList">
                                <CardBody>
                                    <DocumentViewTab addButtonAction={() => setOpenDocumentFormAdd(true)} openDocumentFormAdd={openDocumentFormAdd} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default DocumentList
