import QueryString from 'qs'
import { apiReq } from '..'
import { getFirstDigit } from '../../helpers/number_helper'

export const getDocumentsById = async (id: number | string, page: number, limit: number, module: string, collection: string) => {
    const url = id !== 'null' ? `v1/file/parent/${id}` : 'v1/file'
    const paramsLink = `page=${page}&limit=${limit}&${module !== 'null' ? 'criteria[module]=' + module : ''}&criteria[collection]=${collection}`
    const paramsEncoded = QueryString.stringify(QueryString.parse(paramsLink))

    const response = await apiReq(`${url}?${paramsEncoded}`)
    return response?.data
}

export const deleteDocumentById = async (id: number) => {
    const response = await apiReq(`v1/file/${id}`, {
        method: 'DELETE',
    })

    const isError = getFirstDigit(response.data.status)
    if (isError === 4 || isError === 5) return { ...response.data, id, isError: true }

    return response?.data
}

export const deleteMultipleDocuments = async (arrayId: number[]) => {
    const response = await Promise.all(arrayId.map(async (id) => deleteDocumentById(id)))
    return response
}

export const syncDocumentById = async (id: number) => {
    const response = await apiReq(`v1/file/sync/${id}`, {
        method: 'PUT',
    })
    return response?.data
}
