import { combineActions, handleActions } from 'redux-actions'
import { v4 as uuid } from 'uuid'
import {
    clearQuePropertyGroundPlan,
    clearQuePropertyPhoto,
    createPropertyFailure,
    createPropertyRequest,
    createPropertySuccess,
    deletePropertyFailure,
    deletePropertyGroundPlanSuccess,
    deletePropertyMultipleFailure,
    deletePropertyMultipleRequest,
    deletePropertyMultipleSuccess,
    deletePropertyRequest,
    deletePropertySuccess,
    fetchOpportunityActivityFailure,
    fetchOpportunityActivityRequest,
    fetchOpportunityActivitySuccess,
    fetchOpportunityContactFailure,
    fetchOpportunityContactRequest,
    fetchOpportunityContactSuccess,
    fetchPropertiesContactProfileFailure,
    fetchPropertiesContactProfileRequest,
    fetchPropertiesContactProfileSuccess,
    fetchPropertiesFailure,
    fetchPropertiesRequest,
    fetchPropertiesSuccess,
    fetchPropertyFailure,
    fetchPropertyGroundPlansFailure,
    fetchPropertyGroundPlansRequest,
    fetchPropertyGroundPlansSuccess,
    fetchPropertyPhotoFailure,
    fetchPropertyPhotoRequest,
    fetchPropertyPhotoSuccess,
    fetchPropertyRequest,
    fetchPropertySuccess,
    opportunityShowActivity,
    opportunityShowContact,
    quePropertyGroundPlanSuccess,
    quePropertyPhotoSuccess,
    removePropertiesContactProfile,
    resetPageProperties,
    updatePropertiesAgent,
    updatePropertyFailure,
    updatePropertyRequest,
    updatePropertySuccess,
    uploadPropertyDocumentFailure,
    uploadPropertyGroundPlanSuccess,
    uploadPropertyPhotoFailure,
    uploadPropertyPhotoRequest,
    uploadPropertyPhotoSuccess,
} from './actions'

export const initialState = {
    items: {},
    item: null,
    opportunityShowActivity: true,
    opportunityTitle: 'opportunity.title.activity',
    opportunityDropDownButtonText: 'app.common.activities',
    itemsOpportunityActivity: [],
    fetchingOpportunityActivity: true,
    errorOpportunityActivity: null,
    countOpportunityActivity: 0,
    itemsOpportunityContact: [],
    fetchingOpportunityContact: true,
    errorOpportunityContact: null,
    countOpportunityContact: 0,
    count: 0,
    fetching: true,
    fetchingDetails: false,
    deleting: false,
    error: null,
    errorDetails: null,
    submitting: false,
    photoque: {},
    itemphoto: {},
    itemphotocount: 0,
    fetchingPhotos: false,
    itemgroundplancount: 0,
    groundplanque: {},
    itemgroundplan: {},
    fetchingGroundPlans: false,
    pagination: {},
    links: {},
    itemsPropertiesContactProfile: [],
    fetchingPropertiesContactProfile: false,
    errorPropertiesContactProfile: null,
    countPropertiesContactProfile: 0,
}

export default handleActions(
    {
        [fetchPropertiesRequest]: (state) => ({
            ...state,
            fetching: true,
            error: null,
        }),
        [fetchPropertiesSuccess]: (state, { payload }) => ({
            ...state,
            count: payload?.count ?? 0,
            items: payload?.items,
            fetching: false,
            pagination: payload?.pagination,
        }),
        [fetchPropertiesFailure]: (state, { payload }) => ({
            ...state,
            error: payload,
            fetching: false,
        }),
        [fetchPropertyRequest]: (state) => ({
            ...state,
            fetchingDetails: true,
            errorDetails: null,
        }),
        [fetchPropertySuccess]: (state, { payload }) => {
            return {
                ...state,
                item: payload?.item,
                links: payload.links,
                fetchingDetails: false,
            }
        },
        [fetchPropertyFailure]: (state, { payload }) => ({
            ...state,
            errorDetails: payload,
            fetchingDetails: false,
        }),
        [fetchOpportunityActivityRequest]: (state) => ({
            ...state,
            fetchingOpportunityActivity: true,
            errorOpportunityActivity: null,
        }),
        [fetchOpportunityActivitySuccess]: (state, { payload }) => {
            return {
                ...state,
                itemsOpportunityActivity: payload?.items,
                countOpportunityActivity: payload?.count ?? 0,
                fetchingOpportunityActivity: false,
            }
        },
        [fetchOpportunityActivityFailure]: (state, { payload }) => ({
            ...state,
            errorOpportunityActivity: payload,
            fetchingOpportunityActivity: false,
        }),
        [fetchOpportunityContactRequest]: (state) => ({
            ...state,
            fetchingOpportunityContact: true,
            errorOpportunityContact: null,
        }),
        [fetchOpportunityContactSuccess]: (state, { payload }) => {
            return {
                ...state,
                itemsOpportunityContact: payload?.items,
                countOpportunityContact: payload?.count ?? 0,
                fetchingOpportunityContact: false,
            }
        },
        [fetchOpportunityContactFailure]: (state, { payload }) => ({
            ...state,
            errorOpportunityContact: payload,
            fetchingOpportunityContact: false,
        }),
        [opportunityShowActivity]: (state) => {
            return {
                ...state,
                opportunityShowActivity: true,
                opportunityTitle: 'opportunity.title.activity',
                opportunityDropDownButtonText: 'app.common.activities',
            }
        },
        [opportunityShowContact]: (state) => {
            return {
                ...state,
                opportunityShowActivity: false,
                opportunityTitle: 'opportunity.title.contact',
                opportunityDropDownButtonText: 'app.common.contacts',
            }
        },
        [fetchPropertiesContactProfileRequest]: (state) => ({
            ...state,
            fetchingPropertiesContactProfile: true,
            errorPropertiesContactProfile: null,
        }),
        [fetchPropertiesContactProfileSuccess]: (state, { payload }) => {
            return {
                ...state,
                itemsPropertiesContactProfile: payload?.items,
                countPropertiesContactProfile: payload?.count ?? 0,
                fetchingPropertiesContactProfile: false,
            }
        },
        [fetchPropertiesContactProfileFailure]: (state, { payload }) => ({
            ...state,
            errorPropertiesContactProfile: payload,
            fetchingPropertiesContactProfile: false,
        }),
        [removePropertiesContactProfile]: (state) => {
            return {
                ...state,
                itemsPropertiesContactProfile: [],
                countPropertiesContactProfile: 0,
                fetchingPropertiesContactProfile: false,
            }
        },
        [combineActions(updatePropertyRequest, createPropertyRequest)]: (state, { payload }) => {
            return {
                ...state,
                submitting: true,
            }
        },
        [combineActions(updatePropertySuccess, createPropertySuccess)]: (state, { payload }) => ({
            ...state,
            submitting: false,
        }),
        [combineActions(updatePropertyFailure, createPropertyFailure)]: (state, { payload }) => ({
            ...state,
            submitting: false,
            error: payload,
        }),
        [deletePropertyRequest]: (state) => {
            return {
                ...state,
                error: null,
                deleting: true,
            }
        },
        [deletePropertySuccess]: (state, { payload }) => {
            return {
                ...state,
                deleting: false,
                count: state.count - 1,
            }
        },
        [deletePropertyFailure]: (state, { payload }) => {
            return {
                ...state,
                deleting: false,
                error: payload.response,
            }
        },
        [deletePropertyMultipleRequest]: (state) => {
            return {
                ...state,
                error: null,
                deleting: true,
            }
        },
        [deletePropertyMultipleSuccess]: (state, { payload }) => {
            return {
                ...state,
                deleting: false,
                count: state.count - payload?.length,
            }
        },
        [deletePropertyMultipleFailure]: (state, { payload }) => {
            return {
                ...state,
                deleting: false,
                error: payload.response,
            }
        },

        [quePropertyPhotoSuccess]: (state, { payload }) => {
            console.log('que payload', payload)
            return {
                ...state,
                photoque: {
                    ...state.photoque,
                    ...payload?.reduce((acc, item) => {
                        // let parsedItem = JSON.parse(item);
                        let id = uuid()
                        return {
                            ...acc,
                            [id]: Object.assign(item, {
                                id: id,
                                preview: URL.createObjectURL(item),
                                // formattedSize: formatBytes(file.size),
                                queue: true,
                                uploading: false,
                            }),
                        }
                    }, {}),
                },
            }
        },
        [uploadPropertyPhotoRequest]: (state, { payload }) => {
            // console.log('starting upload reducer', payload)
            return {
                ...state,
                photoque: {
                    ...state.photoque,
                    [payload.id]: Object.assign(payload, {
                        queue: false,
                        uploading: true,
                    }),
                },
            }
        },
        [uploadPropertyPhotoSuccess]: (state, { payload }) => {
            // console.log('uploaded reducer', payload)
            return {
                ...state,
                photoque: {
                    ...state.photoque,
                    [payload.id]: Object.assign(payload, {
                        uploading: false,
                    }),
                },
            }
        },
        [uploadPropertyPhotoFailure]: (state, { payload, file }) => {
            return {
                ...state,
                photoque: {
                    ...state.photoque,
                    [payload?.file?.id]: Object.assign(payload?.file, {
                        uploading: false,
                        error: true,
                    }),
                },
            }
        },
        [clearQuePropertyPhoto]: (state, { payload, file }) => {
            return {
                ...state,
                photoque: {},
            }
        },
        [fetchPropertyPhotoRequest]: (state) => ({
            ...state,
            fetchingPhotos: true,
            error: null,
        }),
        [fetchPropertyPhotoSuccess]: (state, { payload }) => ({
            ...state,
            itemphotocount: payload?.count ?? 0,
            itemphoto: payload?.items?.reduce((acc, item) => {
                // let parsedItem = JSON.parse(item);
                return { ...acc, [item.id]: item }
            }, {}),
            fetchingPhotos: false,
        }),
        [fetchPropertyPhotoFailure]: (state, { payload }) => ({
            ...state,
            error: payload,
            fetchingPhotos: false,
        }),
        [quePropertyGroundPlanSuccess]: (state, { payload }) => {
            // console.log('que payload', payload)
            return {
                ...state,
                groundplanque: {
                    ...payload?.reduce((acc, item) => {
                        // let parsedItem = JSON.parse(item);
                        let id = uuid()
                        return {
                            ...acc,
                            [id]: Object.assign(item, {
                                id: '',
                                tmpId: id,
                                preview: URL.createObjectURL(item),
                                // formattedSize: formatBytes(file.size),
                                queue: true,
                                uploading: false,
                            }),
                        }
                    }, {}),
                },
            }
        },
        [uploadPropertyGroundPlanSuccess]: (state, { payload }) => {
            // console.log('uploaded reducer', payload)
            return {
                ...state,
                groundplanque: {
                    ...state.photoque,
                    [payload.id]: Object.assign(payload, {
                        uploading: false,
                    }),
                },
            }
        },
        [clearQuePropertyGroundPlan]: (state, { payload, file }) => {
            return {
                ...state,
                groundplanque: {},
            }
        },
        [deletePropertyGroundPlanSuccess]: (state, { payload }) => {
            const items = { ...state.itemgroundplan }
            // console.log('items', items)
            delete items[payload]
            return {
                ...state,
                deleting: false,
                itemgroundplan: items,
                itemgroundplancount: state.itemgroundplancount - 1,
            }
        },
        [fetchPropertyGroundPlansRequest]: (state) => ({
            ...state,
            fetchingGroundPlans: true,
            error: null,
        }),
        [fetchPropertyGroundPlansSuccess]: (state, { payload }) => ({
            ...state,
            itemgroundplancount: payload?.count ?? 0,
            itemgroundplan: payload?.items?.reduce((acc, item) => {
                // let parsedItem = JSON.parse(item);
                return { ...acc, [item.id]: item }
            }, {}),
            fetchingGroundPlans: false,
        }),
        [fetchPropertyGroundPlansFailure]: (state, { payload }) => ({
            ...state,
            error: payload,
            fetchingGroundPlans: false,
        }),
        [resetPageProperties]: (state) => ({
            ...state,
            pagination: { ...state.pagination, currentPage: 1 },
        }),
        [updatePropertiesAgent]: (state, { payload }) => {
            const { agent, selectedIds } = payload
            const updatedProperties = state.items.map((item) => (selectedIds.includes(item.id) ? { ...item, agent: [agent] } : item))

            return {
                ...state,
                items: updatedProperties,
            }
        },
    },
    initialState
)
