import { toast } from 'react-toastify'
import { put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { SUBMIT_RESPONSE_STATUS } from '../../common/constants'
import { apiRequest } from '../api'
import * as actions from './actions'
import { selectContactProfileById } from './selectors'
import i18n from '../../i18n'
import { invalidateQueryInTable, getColumnSortFromStore } from '../../api/helpers'

export function* fetchContactProfilesSaga({ payload, meta }) {
    yield put(actions.fetchContactProfilesRequest())

    try {
        const response = yield apiRequest(`v1/contact/${payload}/profile`, {
            method: 'GET',
        })
        yield put(actions.fetchContactProfilesSuccess(response.data.items))
        // meta();
    } catch (error) {
        yield put(actions.fetchContactProfilesFailure(error))
    }
}

export function* fetchContactProfileSaga({ payload, meta }) {
    yield put(actions.fetchContactProfileRequest())
    const { contactId, id, ...params } = payload

    const profile = yield select((state) => selectContactProfileById(state, id))

    if (profile) {
        yield put(actions.fetchContactProfileSuccess(profile))
        return
    }

    try {
        const response = yield apiRequest(`v1/contact/${contactId}/profile/${id}`, {
            method: 'GET',
        })
        yield put(actions.fetchContactProfileSuccess(response.data))
        // meta();
    } catch (error) {
        yield put(actions.fetchContactProfileFailure(error))
    }
}

export function* createContactProfileSaga({ payload, meta }) {
    yield put(actions.createContactProfileRequest())
    const progressToast = toast.loading(i18n.t('toast.loading.contactProfile'))
    const { contactId, ...data } = payload
    try {
        const response = yield apiRequest(`v1/contact/${contactId}/profile`, {
            method: 'POST',
            data: data,
        })

        const profileResponse = yield apiRequest(`v1/contact/${contactId}/profile/${response.data.id}`, {
            method: 'GET',
        })
        yield put(actions.createContactProfileSuccess(profileResponse.data))

        invalidateQueryInTable('contact/profile')

        toast.update(progressToast, { render: i18n.t('toast.update.contactProfile.success'), type: 'success', isLoading: false, autoClose: 3000 })
        if (meta && meta.callback) {
            meta.callback({
                submitStatus: SUBMIT_RESPONSE_STATUS.SUCCESS,
                data: response.data,
            })
        }
    } catch (error) {
        yield put(actions.createContactProfileFailure(error))
        toast.update(progressToast, { render: i18n.t('toast.update.contactProfile.error'), type: 'error', isLoading: false, autoClose: 3000 })
    }
}

export function* updateContactProfileSaga({ payload, meta }) {
    yield put(actions.updateContactProfileRequest())
    const { contactId, id, ...data } = payload
    const progressToast = toast.loading(i18n.t('toast.loading.contactProfile'))
    try {
        const response = yield apiRequest(`v1/contact/${contactId}/profile/${id}`, {
            method: 'PUT',
            data: data,
        })
        yield put(actions.updateContactProfileSuccess(response))
        invalidateQueryInTable('contact/profile')
        toast.update(progressToast, { render: i18n.t('toast.update.contactProfile.success'), type: 'success', isLoading: false, autoClose: 3000 })
        if (meta && meta.callback) {
            meta.callback()
        }
    } catch (error) {
        yield put(actions.updateContactProfileFailure(error))
        toast.update(progressToast, { render: i18n.t('toast.update.contactProfile.error'), type: 'error', isLoading: false, autoClose: 3000 })
    }

    // if(meta && meta.callback){
    //     meta.callback();
    // }
}

export function* deleteContactProfileSaga({ payload, meta }) {
    yield put(actions.deleteContactProfileRequest())
    // console.log('detele', payload)
    const { contactId, id, ...data } = payload
    const progressToast = toast.loading(i18n.t('toast.loading'))
    try {
        const response = yield apiRequest(`v1/contact/${contactId}/profile/${id}`, {
            method: 'DELETE',
        })
        yield put(actions.deleteContactProfileSuccess(payload))

        if (meta && meta.callback) {
            meta.callback()
        }
        invalidateQueryInTable('contact/profile')
        toast.update(progressToast, { render: i18n.t('toast.update.success'), type: 'success', isLoading: false, autoClose: 3000 })
    } catch (error) {
        yield put(actions.deleteContactProfileFailure(error))
        toast.update(progressToast, { render: i18n.t('toast.update.error'), type: 'error', isLoading: false, autoClose: 3000 })
    }
}

export default function* actionWatcher() {
    yield takeLatest(`${actions.fetchContactProfiles}`, fetchContactProfilesSaga)
    yield takeLatest(`${actions.fetchContactProfile}`, fetchContactProfileSaga)
    yield takeEvery(`${actions.createContactProfile}`, createContactProfileSaga)
    yield takeEvery(`${actions.updateContactProfile}`, updateContactProfileSaga)
    yield takeEvery(`${actions.deleteContactProfile}`, deleteContactProfileSaga)
}
