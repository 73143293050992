import QueryString from 'qs'
import { apiReq } from '..'
import { PageListParams } from '../globalTypes'
import { getSearchCriteriaFromStore } from '../helpers'
import { ProjectParams, ProjectPayload, ProjectsDeleteResponse } from './types'

export const getProjectById = async (id: number) => {
    const response = await apiReq(`v1/project/${id}`, {
        params: getSearchCriteriaFromStore('project'),
        paramsSerializer: (params: ProjectParams) => {
            return QueryString.stringify(params)
        },
    })
    return response.data
}

export const getProjects = async (params: PageListParams) => {
    const response = await apiReq('v1/project', {
        params,
        paramsSerializer: (params: PageListParams) => {
            return QueryString.stringify(params)
        },
    })
    return response.data
}

export const createUpdateProject = async (payload: ProjectPayload) => {
    const { isEdit, data } = payload
    const url = isEdit && 'id' in data ? `v1/project/${data.id}` : 'v1/project'

    const response = await apiReq(url, {
        method: isEdit ? 'PUT' : 'POST',
        data: payload.data,
    })
    return response.data
}

export const deleteProjectById = async (id: number) => {
    const response = await apiReq(`v1/project/${id}`, {
        method: 'DELETE',
    })

    if (response.status !== 200) {
        throw new Error()
    }

    return response.data
}

export const deleteProjects = async (ids: number[]) => {
    const response = await Promise.all(ids.map((id) => deleteProjectById(id).catch((error) => ({ id, error }))))
    return response as ProjectsDeleteResponse
}
